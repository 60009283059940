import React from "react";
import {useState,useEffect} from "react";
import {useLocation,useNavigate} from "react-router-dom";
import {Button} from "primereact/button";
import Message from "./Helpers/Message.js";
import {Toolbar} from "primereact/toolbar";
import {Dialog} from "primereact/dialog";
import {Box} from "@mui/system";
import LinearProgress from "@mui/material/LinearProgress";
import Dashboard from "./Sections/Dashboard.js";
import Invoice from "./Sections/Invoice.js";
import UserSettings from "./Sections/UserSettings.js";
import Expenses from "./Sections/Expenses.js";
import Inventory from "./Sections/Inventory.js";
import Invoices from "./Sections/Invoices.js";
import PL from "./Sections/PL.js";
import Clients from "./Sections/Clients.js";
import {fetch_url_post} from "../Sources/js/app.js";

let oper="";
let defaultDialogValues={
	openUserSettings: false,
	titleUserSettings: "User settings",
};

export default function MainPage(props) {

	const dataOk=(props.session.sessId?true:false);
	const [menuOpen, setMenuOpen] = useState(false);
	const [menuSections, setMenuSections] = useState([]);
	const [messageText, setMessageText] = useState(null);
	const [dialogValues, setDialogValues] = useState(defaultDialogValues);
    const location = useLocation();
    const navigate = useNavigate();

	const handleButton = (evt,what) => {
		if(what==="menu") {
			setMenuOpen(!menuOpen);
		}
		if(what==="user_settings") {
			setDialogValues({...dialogValues,openUserSettings: true});
		}
		if(what==="home") {
			navigate("/");
		}
	}

	const success_handler = (result) => {
		if(result.ok===0) {
			setMessageText("Error: "+result.error);
		}
		if(result.is_auth===0) {
			navigate("/login");			
			return false;
		}
		if(result.message) {
			setMessageText(result.message);			
		}
		if(oper==="getMenuSections") {
			setMenuSections(result.data);
		}
		if(oper==="validateSession") {
			if(result.is_auth===1) {
				props.loginHandler(result);
			}else{
				navigate("/login");
			}
		}
		oper="";
	}

	const fail_handler = () => {
		setMessageText("Connection error. Please try again later!");
	}

	useEffect(() => {
//		console.log("got useEffect() in main with "+props.session.sessId);
		window.localStorage.removeItem("docReturnPathXa7");
		window.localStorage.setItem("docReturnPathXa7",(location.pathname?location.pathname:"/"));
		if(!props.session.sessId) {
			if(!window.localStorage.getItem("docSessIdXa7")) {
//				console.log("Implicit redirect to login...");
				navigate("/login");						
			}
			validateSession(window.localStorage.getItem("docSessIdXa7"));
		}else{
			oper="getMenuSections";
			let url="getMenuSections";
			url+="?session_id="+props.session.sessId;
			let data={};
//			fetch_url_post(url,data,success_handler,fail_handler);
		}
	},[props.session.sessId]);

	const validateSession = (sessionId) => {
//		console.log("Try to validate session...");
		oper="validateSession";
		let url="validateSession";
		url+="?session_id="+sessionId;
		let data={};
		fetch_url_post(url,data,success_handler,fail_handler);
	}

	const handleSection = (evt,section) => {
		//used only for menu sections and appbar sections
		setMenuOpen(false);
		switch(section) {
			case "Dashboard":
				navigate("/");
				break;
		}
	}

	const getCurrentSection = () => {
		//used to render current content from all sources
		switch(location.pathname) {
			case "/invoice":
				return(
					<Invoice session={props.session}/>
				);
			case "/expenses":
				return(
					<Expenses session={props.session}/>
				);
			case "/pl":
				return(
					<PL session={props.session}/>
				);
			case "/inventory":
				return(
					<Inventory session={props.session}/>
				);
			case "/invoices":
				return(
					<Invoices session={props.session}/>
				);
			case "/clients":
				return(
					<Clients session={props.session}/>
				);
			default:
				return(
					<Dashboard session={props.session}/>
				);
		}
	}

	const translateSection = () =>{
		//used for main dialog title
		switch(location.pathname) {
			case "/invoice":
				return "New invoice";
				break;
			case "/expenses":
				return "Expenses";
				break;
			case "/pl":
				return "Profit & Loss";
				break;
			case "/inventory":
				return "Inventory";
				break;
			case "/invoices":
				return "Invoices list";
				break;
			case "/clients":
				return "Clients list";
				break;
			default:
				return "Dashboard";
		}
	}

	const closeUserSettings = (message) =>{
		setDialogValues({...dialogValues,openUserSettings:false});
		setMessageText(message)
	}

    const leftToolBarContents = (
        <React.Fragment>
			<Box sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block", xl: "block" } }}>
				<img src="assets/layout/images/login/MFA_logo_name.png" alt="" width="90px"/>
			</Box>
			<Box sx={{ display: { xs: "block", sm: "block", md: "none", lg: "none", xl: "none" } }}>
				<img src="assets/layout/images/login/MFA_logo_name.png" alt="" width="90px"/>
			</Box>
        </React.Fragment>
    );

    const rightToolBarContents = (
        <React.Fragment>
			<Box sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block", xl: "block" } }}>
				<p style={{"marginRight":"12px","display":"inline","lineHeight":"2.1","color":"grey"}}>{props.session.customer_name} - [ {props.session.name} ]</p>
				<Button label="Home" icon="pi pi-home" tooltip="Home" style={{"marginRight":"18px"}} className="p-button-sm p-button-success p-button-outlined" onClick={(evt) => handleButton(evt,"home")}/>
				<Button label="Settings" icon="pi pi-cog" tooltip="User settings" style={{"marginRight":"9px"}} className="p-button-sm p-button-success p-button-outlined" onClick={(evt) => handleButton(evt,"user_settings")}/>
				<Button label="Logout" icon="pi pi-power-off" tooltip="Logout" className="p-button-sm p-button-danger p-button-outlined" onClick={(evt) => props.logoutHandler(evt)}/>
			</Box>
			<Box sx={{ display: { xs: "block", sm: "block", md: "none", lg: "none", xl: "none" } }}>
				<Button icon="pi pi-home" tooltip="Home" style={{"marginRight":"12px"}} className="p-button-sm p-button-success p-button-outlined" onClick={(evt) => handleButton(evt,"home")}/>
				<Button icon="pi pi-cog" tooltip="User settings" style={{"marginRight":"9px"}} className="p-button-sm p-button-success p-button-outlined" onClick={(evt) => handleButton(evt,"user_settings")}/>
				<Button icon="pi pi-power-off" tooltip="Logout" className="p-button-sm p-button-success p-button-outlined" onClick={(evt) => props.logoutHandler(evt)}/>
			</Box>
        </React.Fragment>
    );

	const userSettingsDialog = () => {
		return(
			<Dialog header={dialogValues.titleUserSettings} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openUserSettings:false})} className="responsive-dialog-small-med" contentStyle={{"paddingBottom":"15px"}}>
				<UserSettings session={props.session} closeHandler={closeUserSettings}/>
			</Dialog>
		);
	}

	if(dataOk) {
		return(
			<div style={{}}>
				<Toolbar left={leftToolBarContents} right={rightToolBarContents} className="main-toolbar" style={{"backgroundColor":"#FFFFFF","backgroundImage":"none"}}/>
				<div style={{"minHeight":"87.9vh","padding":"15px"}}>
					<div style={{"textAlign":"left","marginLeft":"12px","color":"#000000"}}>
						<h4>{translateSection()}</h4>
					</div>
					{getCurrentSection()}				
				</div>
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
				{dialogValues.openUserSettings && userSettingsDialog()}
			</div>
		);
	}else{
		return(
			<React.Fragment>
				<LinearProgress />
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
			</React.Fragment>
		);
	}
}
