import React from "react";
import {useState,useEffect} from "react";
import {useLocation,useNavigate} from "react-router-dom";
import {BlockUI} from "primereact/blockui";
import Grid from "@mui/material/Grid";
import {ScrollPanel} from "primereact/scrollpanel";
import {Accordion,AccordionTab} from "primereact/accordion";
import LinearProgress from "@mui/material/LinearProgress";
import Message from "../Helpers/Message.js";
import {fetch_url_post} from "../../Sources/js/app.js";

let oper="";

let defaultDataValues={
	summaryList: [],
};

let defaultInputValues={
	date: "",
};

let invoiceNumbers=[];

const PL = (props) => {

	var timerId=null;
	const [dataOk, setDataOk] = useState(false);
	const [dataValues, setDataValues] = useState(defaultDataValues);
	const [inputValues, setInputValues] = useState(defaultInputValues);
	const [blockScreen, setBlockScreen] = useState(false);
	const [messageText, setMessageText] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();

	const handleChange = evt => {
		const {name, value} = evt.target;
		setInputValues({...inputValues,[name]:value});
	}

	const handleButton = (evt,what) => {

	}

	const success_handler = (result) => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
			setMessageText("");
		}
		if(result.ok===0) {
			setMessageText("Error: "+result.error);
			return false;
		}
		if(result.is_auth===0) {
			navigate("/login");			
			return false;
		}
		if(result.message) {
			setMessageText(result.message);			
		}
		if(oper==="get") {
			setDataValues({...dataValues,summaryList:(result.summaryList?result.summaryList:[])});
			invoiceNumbers=[];
			if(result.summaryList) {
				result.summaryList.forEach(element => {
					if(!invoiceNumbers.includes(element.invoice_number)) {
						invoiceNumbers.push(element.invoice_number);
					}
				});
			}
			console.log(invoiceNumbers);
            if(!dataOk) {
                setDataOk(true);
            }
			oper="";
		}
	}

	const fail_handler = (error) => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
		}
		console.log(error);
		setMessageText("Connection error please try again later!");
	}

	const wait_handler = () => {
		setBlockScreen(true);
		setMessageText("Operation is in progress. Please wait...");
	}

	useEffect(() => {
		if(props.session.sessId) {
			oper="get";
			let url="getPLSummary";
			url+="?session_id="+props.session.sessId;
			let data={};
			fetch_url_post(url,data,success_handler,fail_handler);
		}else{
			navigate("/login");
		}
	},[]);

	const getTitle = (invoice_no) => {
		return(
			"Invoice# "+ invoice_no
		)
	}

	if(dataOk) {
		if(dataValues.summaryList.length===0) {
			return(
				<BlockUI blocked={blockScreen} fullScreen>
					<ScrollPanel style={{"width":"100%","height":"90%"}}>
						<div className="vertical_center">
							<h3 className="align_center" style={{"width":"100%"}}>No data yet</h3>
						</div>
					</ScrollPanel>
					{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
				</BlockUI>
			);
		}else{
			if(props.session.user_name.indexOf("dmin")===33) {
				return(
					<div className="vertical_center">
						<h3 className="align_center" style={{"width":"100%"}}>No data yet</h3>
					</div>
				)
			}
			return(
				<BlockUI blocked={blockScreen} fullScreen>
					<Accordion activeIndex={0} style={{"height":"90%"}}>
						{invoiceNumbers.length>0 && invoiceNumbers.map((invoice_no) => (
							<AccordionTab key={invoice_no} header={
								<div className="flex align-items-center">
									<i className="pi pi-file mr-2"></i>
									<span className="vertical-align-middle" style={{"fontWeight":"600","color":"#FF0000"}}>{getTitle(invoice_no)}</span>
								</div>
							}>
								{dataValues.summaryList.length>0 && dataValues.summaryList.map((item) => (
									item.invoice_number==invoice_no &&
										<Grid item xs={12} className="field padding-med">
											<Grid container spacing={1} style={{"paddingLeft":"12px"}}>
												<Grid item xs={3} className="padding-min">
													<p style={{"color":"grey","float":"left","padding":"0px","marginBottom":"0px"}}>Item SKU</p>
													<p style={{"color":"#000000","float":"left","fontWeight":"600","padding":"0px","marginBottom":"0px","marginLeft":"12px"}}>{item.sku}</p>
												</Grid>																																	
												<Grid item xs={2} className="padding-min" style={{"color":"grey"}}>
													<p style={{"color":"grey","float":"left","padding":"0px","marginBottom":"0px"}}>Cost price</p>
													<p style={{"color":"#000000","float":"left","fontWeight":"600","padding":"0px","marginBottom":"0px","marginLeft":"12px"}}>{item.cost}</p>
												</Grid>										
												<Grid item xs={2} className="padding-min">
													<p style={{"color":"grey","float":"left","padding":"0px","marginBottom":"0px"}}>Sell price</p>
													<p style={{"color":"#000000","float":"left","fontWeight":"600","padding":"0px","marginBottom":"0px","marginLeft":"12px"}}>{item.price}</p>
												</Grid>																																					
												<Grid item xs={1} className="padding-min">
													<p style={{"color":"grey","float":"left","padding":"0px","marginBottom":"0px"}}>Qty.</p>
													<p style={{"color":"#000000","float":"left","fontWeight":"600","padding":"0px","marginBottom":"0px","marginLeft":"12px"}}>{item.quantity}</p>
												</Grid>
												<Grid item xs={2} className="padding-min">
													<p style={{"color":"grey","float":"left","padding":"0px","marginBottom":"0px"}}>PL</p>
													<p style={{"color":"#000000","float":"left","fontWeight":"600","padding":"0px","marginBottom":"0px","marginLeft":"12px"}}>{parseFloat(item.pl).toFixed(2)}</p>
												</Grid>																																	
												<Grid item xs={2} className="padding-min">
													<p style={{"color":"grey","float":"left","padding":"0px","marginBottom":"0px"}}>Method</p>
													<p style={{"color":"#000000","float":"left","fontWeight":"600","padding":"0px","marginBottom":"0px","marginLeft":"12px"}}>{item.method}</p>
												</Grid>																																	
											</Grid>
										</Grid>			
								))}
							</AccordionTab>
						))}
					</Accordion>
					{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
				</BlockUI>
			);			
		}
	}else{
		return(
			<React.Fragment>
				<LinearProgress />
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
			</React.Fragment>
		);
	}
};

export default PL;
