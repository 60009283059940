import React from "react";
import {useState,useEffect} from "react";
import {useLocation,useNavigate} from "react-router-dom";
import {BlockUI} from "primereact/blockui";
import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";
import {Box} from "@mui/system";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Calendar} from "primereact/calendar";
import {InputText} from "primereact/inputtext";
import {ConfirmDialog} from "primereact/confirmdialog";
import {confirmDialog} from "primereact/confirmdialog";
import {Dialog} from "primereact/dialog";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import Message from "../Helpers/Message.js";
import {fetch_url_post,get_monday,format_date,fetch_download} from "../../Sources/js/app.js";
import {get_api_path} from "../../Sources/js/paths.js";

let oper="";

let defaultInputValues={
	period: "today",
	start_date: new Date(),
	stop_date: new Date(),
	filter_name: "",
	phone: "",
	email: "",
};

let defaultDataValues={
	invoicesList: [],
	paymentsList: [],
};

let defaultDialogValues={
	openViewInvoice: false,
	titleViewInvoice: "",
	openAddPhone: false,
	titleAddPhone: "",
	openAddEmail: false,
	titleAddEmail: "",
	openPayments: false,
	titlePayments: "",
	openAddPayments: false,
	titleAddPayments: "",
};

let defaultInputValuesPayments={
	id: null,
	amount: "",
	payment_type: "",
	payment_date: new Date(),
	details: "",
};

let periodsList=[
	{label: "Today",value: "today"},
//	{label: "Yesterday",value: "yesterday"},
	{label: "Current week",value: "c_week"},
//	{label: "Pervious week",value: "p_week"},	
	{label: "Current month",value: "c_month"},
//	{label: "Previous month",value: "p_month"},
	{label: "Custom interval",value: "custom"},
]

let paymentsTypesList=[
	{label: "CASH",value: "CASH"},
	{label: "BANK",value: "BANK"},
	{label: "PAYMENT PROCESSOR",value: "PAYMENT PROCESSOR"},
	{label: "OTHER",value: "OTHER"},
]

let currentInvoice={};

const Invoices = (props) => {

	var timerId=null;
	const [dataOk, setDataOk] = useState(false);
	const [blockScreen, setBlockScreen] = useState(false);
	const [messageText, setMessageText] = useState(null);
	const [inputValues, setInputValues] = useState(defaultInputValues);
	const [dataValues, setDataValues] = useState(defaultDataValues);
	const [customDates, setCustomDates] = useState(false);
	const [invoicesList, setInvoicesList] = useState([]);
	const [dialogValues, setDialogValues] = useState(defaultDialogValues);
	const [pdfUrl, setPdfUrl] = useState(null);
    const [expandedRows, setExpandedRows] = useState(null);
	const [selectedInvoice, setSelectedInvoice] = useState({});
	const [inputValuesPayments, setInputValuesPayments] = useState(defaultInputValuesPayments);
	const [inputErrorPayments, setInputErrorPayments] = useState("");
    const location = useLocation();
    const navigate = useNavigate();

	let row_ct=0;

	const handleButton = (evt,what,elementData) => {
		if(what==="update") {
			let data={};
			let d=new Date();
			if(inputValues.period==="today") {
				data.start_date=format_date(d,"yyyy-mm-dd");
			}
			if(inputValues.period==="yesterday") {
				data.start_date=format_date(new Date(d.setDate(d.getDate()-1)),"yyyy-mm-dd");
			}
			if(inputValues.period==="c_week") {
				data.start_date=format_date(get_monday(d),"yyyy-mm-dd");
			}
			if(inputValues.period==="p_week") {
				data.start_date=format_date(get_monday(d,1),"yyyy-mm-dd");
			}
			if(inputValues.period==="c_month") {
				data.start_date=format_date(new Date(d.getFullYear(),d.getMonth(),1),"yyyy-mm-dd");
			}
			if(inputValues.period==="p_month") {
				data.start_date=format_date(new Date(d.getFullYear(),d.getMonth()-1,1),"yyyy-mm-dd");
			}
			if(inputValues.period==="custom") {
				if((inputValues.start_date)&&(inputValues.stop_date)) {
					data.start_date=format_date(inputValues.start_date,"yyyy-mm-dd");
					data.stop_date=format_date(inputValues.stop_date,"yyyy-mm-dd");
				}else{
					setMessageText("Error: Select time interval!");				
				}
			}
			oper="get";
			let url="getInvoicesList";
			url+="?session_id="+props.session.sessId;
			fetch_url_post(url,data,success_handler,fail_handler);				
		}
		if(what==="view_invoice") {
			setPdfUrl(get_api_path()+"getPdfInvoice"+"?session_id="+props.session.sessId+"&hash="+elementData.invoice_hash);
			setDialogValues({...dialogValues,openViewInvoice: true,titleViewInvoice: "Invoice #"+elementData.number});
		}
		if(what==="download_invoice") {
			let url="getPdfInvoice";
			url+="?session_id="+props.session.sessId;
			let data={
				id: elementData.id,
			};
			fetch_download(url,data,"Invoice_"+elementData.number+".pdf",success_handler);
		}
		if(what==="whatsapp") {
			setInputValues({...inputValues,phone:(elementData.client_phone?elementData.client_phone:"")});
			setDialogValues({...dialogValues,openAddPhone: true,titleAddPhone: "Enter phone number"});			
			setSelectedInvoice(elementData);
		}
		if(what==="send_whatsapp") {
			let ok=0;
			//first check if we have a valid phone number
			let phone=(selectedInvoice.client_phone?selectedInvoice.client_phone:inputValues.phone);
			phone=phone.trim();
			if(phone.length) {
				//if the phone starts with 00 remove them
				phone=phone.replace(/^0+/,"");
				//after that check if length>10
				if(phone.length>10) {
					ok=1;
				}
			}else{
				if(elementData===1) {
					setMessageText("Error: Please enter phone number!");			
					return false;					
				}
			}
			if(ok===1) {
				let text="Dear customer,\n\nHere you can download the invoice:\n\n https://clients.mfaconsultancyllc.com/api3/cInvoices?hash="+selectedInvoice.invoice_hash+"&ctls=true&app=w"+"\n\nThank you for choosing us.\n";
				let location="https://wa.me/"+phone+"?text="+escape(text);
				document.location=(location);
				setDialogValues(defaultDialogValues);
			}else{
				setDialogValues({...dialogValues,openAddPhone: true,titleAddPhone: "Enter phone number"});
				if(elementData===1) {
					setMessageText("Error: Invalid telephone number!");								
				}
			}
		}
		if(what==="email") {
			setInputValues({...inputValues,email:(elementData.client_email?elementData.client_email:"")});
			setDialogValues({...dialogValues,openAddEmail: true,titleAddEmail: "Enter email address"});			
			setSelectedInvoice(elementData);
		}
		if(what==="send_email") {
			let ok=0;
			//first check if we have a valid email address
			let email=(selectedInvoice.client_email?selectedInvoice.client_email:inputValues.email);
			email=email.trim();
			if(email.length) {
				console.log("We have email: "+email);
				let mail_format = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
				if(email.match(mail_format)) {
					ok=1;
				}
			}else{
				if(elementData===1) {
					setMessageText("Error: Please enter email address!");			
					return false;					
				}
			}
			if(ok===1) {
				if(selectedInvoice.id) {
					let url="emailInvoice";
					url+="?session_id="+props.session.sessId;
					oper="email_or_pdf";
					let data={
						id: selectedInvoice.id,
						to: email,
						customer_name: props.session.customer_name,
					};
					timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
					setDialogValues(defaultDialogValues);
				}else{
					setMessageText("Error: Invalid invoice details!");													
				}
			}else{
				setDialogValues({...dialogValues,openAddEmail: true,titleAddEmail: "Enter email address"});
				if(elementData===1) {
					setMessageText("Error: Invalid email address!");								
				}
			}
		}
		if(what==="request_deletion") {
			oper="update_rfd";
			let url="updateRFD";
			url+="?session_id="+props.session.sessId;
			let data={};
			if(inputValues.stop_date) {
				data={
					id: elementData.id,
					type: 1,
					start_date: format_date(inputValues.start_date,"yyyy-mm-dd"),
					stop_date: format_date(inputValues.stop_date,"yyyy-mm-dd"),
				};
			}else{
				data={
					id: elementData.id,
					type: 1,
					start_date: format_date(inputValues.start_date,"yyyy-mm-dd"),
				};
			}
			fetch_url_post(url,data,success_handler,fail_handler);				
		}
		if(what==="cancel_request_deletion") {
			oper="update_rfd";
			let url="updateRFD";
			url+="?session_id="+props.session.sessId;
			let data={};
			if(inputValues.stop_date) {
				data={
					id: elementData.id,
					type: 0,
					start_date: format_date(inputValues.start_date,"yyyy-mm-dd"),
					stop_date: format_date(inputValues.stop_date,"yyyy-mm-dd"),
				};
			}else{
				data={
					id: elementData.id,
					type: 0,
					start_date: format_date(inputValues.start_date,"yyyy-mm-dd"),
				};
			}
			fetch_url_post(url,data,success_handler,fail_handler);				
		}
		if(what==="export_xls") {
			let url="getXLSInvoicesList";
			let oper="download";
			url+="?session_id="+props.session.sessId;
			let data={
				filter: inputValues.filter_name,
			};
			let d=new Date();
			if(inputValues.period==="today") {
				data.start_date=format_date(d,"yyyy-mm-dd");
			}
			if(inputValues.period==="yesterday") {
				data.start_date=format_date(new Date(d.setDate(d.getDate()-1)),"yyyy-mm-dd");
			}
			if(inputValues.period==="c_week") {
				data.start_date=format_date(get_monday(d),"yyyy-mm-dd");
			}
			if(inputValues.period==="p_week") {
				data.start_date=format_date(get_monday(d,1),"yyyy-mm-dd");
			}
			if(inputValues.period==="c_month") {
				data.start_date=format_date(new Date(d.getFullYear(),d.getMonth(),1),"yyyy-mm-dd");
			}
			if(inputValues.period==="p_month") {
				data.start_date=format_date(new Date(d.getFullYear(),d.getMonth()-1,1),"yyyy-mm-dd");
			}
			if(inputValues.period==="custom") {
				data.start_date=format_date(inputValues.start_date,"yyyy-mm-dd");
				data.stop_date=format_date(inputValues.stop_date,"yyyy-mm-dd");
			}
			fetch_download(url,data,"invoices_report",success_handler);
			setBlockScreen(true);
			setMessageText("Downloading file, please wait...");
		}
		if(what==="payments") {
			currentInvoice={...elementData};
			oper="get_payments";
			let url="getPayments";
			url+="?session_id="+props.session.sessId;
			let data={
				invoice_id: elementData.id,
			};
			fetch_url_post(url,data,success_handler,fail_handler);	
		}
		if(what==="add_payment") {
			setInputValuesPayments({...defaultInputValuesPayments,amount:currentInvoice.rest});
			setInputErrorPayments("");
			setDialogValues({...dialogValues,openAddPayments: true,titleAddPayments: "Add new payment ["+currentInvoice.currency+"]"});
		}
		if(what==="edit_payment") {
			setInputErrorPayments("");
			let tmp={...elementData};
			tmp.payment_date=new Date(tmp.payment_date);
			setInputValuesPayments(tmp);
			setDialogValues({...dialogValues,openAddPayments: true,titleAddPayments: "Edit payment ["+currentInvoice.currency+"]"});
		}
		if(what==="save_payment") {
			console.log(currentInvoice);
			document.querySelectorAll("input").forEach((element)=>element.classList.remove("required_input_error"));
			setInputErrorPayments("");
			if((inputValuesPayments.amount==="")||(inputValuesPayments.amount==="0")) {
				setInputErrorPayments("Please fill amount!");				
				document.getElementById("amount").classList.add("required_input_error");
				return false;
			}
			if(!inputValuesPayments.payment_type) {
				setInputErrorPayments("Please select type!");				
				document.getElementById("payment_type").classList.add("required_input_error");
				return false;
			}
			let url="savePayment";
			url+="?session_id="+props.session.sessId;
			oper="save_payment";
			let data={...inputValuesPayments};
			data.payment_date=format_date(data.payment_date,"yyyy-mm-dd");
			data.invoice_id=currentInvoice.id;
			data.currency=currentInvoice.currency;
			timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
		}
		if(what==="delete_payment") {
			oper="delete_payment";
			let url="deletePayment";
			url+="?session_id="+props.session.sessId;
			let data={
				id: elementData.id,
			};
			fetch_url_post(url,data,success_handler,fail_handler);	
		}
	}

	const handleChange = evt => {
		const {name, value} = evt.target;
		if(name==="period") {
			if(value) {
				//form date
				let start_date="";
				let d=new Date();
				setCustomDates(false);
				if(value==="today") {
					start_date=format_date(d,"yyyy-mm-dd");
				}
				if(value==="yesterday") {
					start_date=format_date(new Date(d.setDate(d.getDate()-1)),"yyyy-mm-dd");
				}
				if(value==="c_week") {
					start_date=format_date(get_monday(d),"yyyy-mm-dd");
				}
				if(value==="p_week") {
					start_date=format_date(get_monday(d,1),"yyyy-mm-dd");
				}
				if(value==="c_month") {
					start_date=format_date(new Date(d.getFullYear(),d.getMonth(),1),"yyyy-mm-dd");
				}
				if(value==="p_month") {
					start_date=format_date(new Date(d.getFullYear(),d.getMonth()-1,1),"yyyy-mm-dd");
				}
				if(value==="custom") {
					setCustomDates(true);
				}
				if(value!=="custom") {
					oper="get";
					let url="getInvoicesList";
					url+="?session_id="+props.session.sessId;
					let data={
						start_date: start_date,
					};
					fetch_url_post(url,data,success_handler,fail_handler);				
				}
			}
		}
		if(name==="filter_name") {
			setInvoicesList(dataValues.invoicesList.filter((item)=>((item.client_name.toLowerCase().indexOf(value.toLowerCase())>-1))||(value==="")));
		}
		setInputValues({...inputValues,[name]:value});
	}

	const handleChangePayments = evt => {
		const {name, value} = evt.target;
		setInputErrorPayments("");							
		if(name==="amount") {
			if(!evt.target.validity.valid) {
				setInputErrorPayments("Only numbers are allowed!");							
				return false;
			}
			if(currentInvoice.partial_payments==0) {
				setInputErrorPayments("Partial payments not allowed!");							
				return false;				
			}
		}
		setInputValuesPayments({...inputValuesPayments,[name]:value});
	}

	const success_handler = (result) => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
			setMessageText("");
		}
		if(result.ok===0) {
			setMessageText("Error: "+result.error);
			return false;
		}
		if(result.is_auth===0) {
			navigate("/login");			
			return false;
		}
		if(result.message) {
			setMessageText(result.message);			
		}
		if((oper==="init")||(oper==="get")||(oper==="update_rfd")) {
			setDataValues({...dataValues,invoicesList:(result.invoicesList?result.invoicesList:[])});
			setInvoicesList(result.invoicesList?result.invoicesList:[]);
            if(!dataOk) {
                setDataOk(true);
            }
			oper="";
		}
		if(oper==="download") {
			setMessageText(null);
			setBlockScreen(false);
			oper="";
		}
		if((oper==="get_payments")||(oper==="save_payment")||(oper==="delete_payment")) {
			if(oper==="get_payments") {
				setDataValues({...dataValues,
											paymentsList:(result.paymentsList?result.paymentsList:[]),
											partial_payments: result.partial_payments,
											invoice_total: result.total,
				});
				setDialogValues({...dialogValues,openPayments: true,titlePayments: "Payments for invoice "+currentInvoice.number_date});
			}else{
				//we must modify invoices list (dataValues.invoicesList)
				const updatedInvoicesList=dataValues.invoicesList.map(invoice => {
																			if(invoice.id===currentInvoice.id) {
																				let tmp=invoice;
																				tmp.payed_amount=result.paymentsList.reduce((accumulator,currentValue) => {
																					const numericValue=parseFloat(currentValue.amount);
																					return accumulator+numericValue;
																				},0);
																				return {...invoice, ...tmp};
																			}
																			return invoice;
				});
				setDataValues({...dataValues,paymentsList:(result.paymentsList?result.paymentsList:[]),
											invoicesList:updatedInvoicesList,
				});
				setDialogValues({...dialogValues,openAddPayments: false,titleAddPayments: ""});				
			}
			//update amount
			let tmp=result.paymentsList.reduce((accumulator,currentValue) => {
				const numericValue=parseFloat(currentValue.amount);
				return accumulator+numericValue;
			},0);
			tmp=parseFloat(currentInvoice.total)-tmp;
			currentInvoice.rest=tmp;
			oper="";
		}
	}

	const fail_handler = () => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
		}
		setMessageText("Connection error please try again later!");
	}

	const wait_handler = () => {
		setBlockScreen(true);
		setMessageText("Operation is in progress. Please wait...");
	}

	useEffect(() => {
		if(props.session.sessId) {
			oper="init";
			let url="getInvoicesList";
			url+="?session_id="+props.session.sessId;
			let data={
				start_date: format_date(new Date(),"yyyy-mm-dd"),
			};
			fetch_url_post(url,data,success_handler,fail_handler);
		}else{
			navigate("/login");
		}
	},[]);

	const tableColTemplate = (row_data,column) => {
		if(column.field!=="buttons") {
			if(row_data.status==="DELETED") {
				if((column.field==="currency")||(column.field==="status")||(column.field==="sended")) {
					return(
						<span style={{"paddingLeft":"12px","textDecoration":"line-through"}}>{row_data[column.field]}</span>
					)
				}else{
					if(column.field==="payed_amount") {
						return(
							<span style={{"paddingLeft":"30px","textDecoration":"line-through"}}>{row_data[column.field]}</span>
						)
					}
					return(
						<span style={{"paddingLeft":"1px","textDecoration":"line-through"}}>{row_data[column.field]}</span>
					);
				}
			}else{
				if((column.field==="currency")||(column.field==="status")||(column.field==="sended")) {
					return(
						<span style={{"paddingLeft":"12px"}}>{row_data[column.field]}</span>
					)
				}else{
					if(column.field==="payed_amount") {
						return(
							<span style={{"paddingLeft":"36px"}}>{row_data[column.field]}</span>
						)
					}
					return(
						<span style={{"paddingLeft":"1px"}}>{row_data[column.field]}</span>
					);
				}
			}
		}
		if(column.field==="buttons") {
			return(
				<span>
					<Button label="View" icon="pi pi-list" className="p-button-sm p-button-raised" style={{"marginRight":"3px"}} onClick={(evt) => handleButton(evt,"view_invoice",row_data)}/>
				</span>
			);
		}
	}

	const tableColTemplatePayments = (row_data,column) => {
		if(column.field!=="buttons") {
			if(column.field==="currency") {
				return(
					<span style={{"paddingLeft":"18px"}}>{row_data[column.field]}</span>					
				)
			}
			return(
				<span style={{"paddingLeft":"1px"}}>{row_data[column.field]}</span>
			)
		}
		if(column.field==="buttons") {
			return(
				<span>
					<Button label="Edit" icon="pi pi-pencil" className="p-button-sm p-button-raised" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"edit_payment",row_data)}/>
					<Button label="Delete" icon="pi pi-trash" className="p-button-sm p-button-raised p-button-danger" style={{}} onClick={(evt) => confirm(evt,"delete_payment",row_data)}/>
				</span>
			);
		}
	}	

    const rowExpansionTemplate = (data) => {
        return (
            <div>
				<div style={{"paddingLeft":"3rem"}}>
					<Button label="Download" icon="pi pi-download" className="p-button-sm p-button-raised" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"download_invoice",data)}/>                                                  
					<Button label="Sendmail" icon="pi pi-envelope" className="p-button-sm p-button-raised" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"email",data)}/>                                                  
					<Button label="Whatsapp" icon="pi pi-whatsapp" className="p-button-sm p-button-raised" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"whatsapp",data)}/>          
					{!!((data.status==="VALID")&&(props.session.delete_invoices===1)) &&
						<Button label="Request deletion" icon="pi pi-trash" className="p-button-sm p-button-danger p-button-raised" style={{"marginRight":"6px"}} onClick={(evt) => confirm(evt,"request_deletion",data)}/>                                                  
					}
					{!!((data.status==="DELETE_REQUESTED")&&(props.session.delete_invoices===1)) &&
						<Button label="Cancel deletion request" icon="pi pi-times" className="p-button-sm p-button-warning p-button-raised" style={{"marginRight":"6px"}} onClick={(evt) => confirm(evt,"cancel_request_deletion",data)}/>                                                  
					}
					{!!(props.session.payments==1) &&
						<Button label="Payments" icon="pi pi-dollar" className="p-button-sm p-button-raised" style={{}} onClick={(evt) => handleButton(evt,"payments",data)}/>                                                  
					}
				</div>
            </div>
        );
    };

	const viewInvoiceDialog = () => {
		if(pdfUrl) {
			return(
				<Dialog header={dialogValues.titleViewInvoice} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openViewInvoice:false})} className="responsive-dialog-big">
					<iframe src={`${pdfUrl}#view=fitH`} title="PDF" width="100%" height="501px"></iframe>
				</Dialog>
			);
		}
	}

	const addPhoneDialog = () => {
		return(
			<Dialog header={dialogValues.titleAddPhone} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openAddPhone:false})} className="responsive-dialog-small">
				<Grid container spacing={1} style={{"paddingTop":"12px"}}>
					<Grid item xs={12} sm={12} md={12} lg={12} className="field padding-med">
						<label htmlFor="phone">Phone (intl. format with 00)</label>
						<InputText name="phone" id="phone" pattern="[0-9]*" inputmode="numeric" style={{"width":"100%"}} value={inputValues.phone} onChange={(evt) => handleChange(evt)}/>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} style={{"textAlign":"right","marginTop":"12px"}}>
						<Button label="Send" icon="pi pi-whatsapp" className="p-button-sm p-button-success p-button-raised" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"send_whatsapp",1)}/>
						<Button label="Close" icon="pi pi-times" className="p-button-sm p-button-danger p-button-raised" onClick={(evt)=>setDialogValues({...dialogValues,openAddPhone:false})}/>
					</Grid>
				</Grid>
			</Dialog>
		);
	}

	const addEmailDialog = () => {
		return(
			<Dialog header={dialogValues.titleAddEmail} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openAddEmail:false})} className="responsive-dialog-small">
				<Grid container spacing={1} style={{"paddingTop":"12px"}}>
					<Grid item xs={12} sm={12} md={12} lg={12} className="field padding-med">
						<label htmlFor="email">Email address</label>
						<InputText name="email" id="phone" style={{"width":"100%"}} value={inputValues.email} onChange={(evt) => handleChange(evt)}/>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} style={{"textAlign":"right","marginTop":"12px"}}>
						<Button label="Send" icon="pi pi-envelope" className="p-button-sm p-button-success p-button-raised" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"send_email",1)}/>
						<Button label="Close" icon="pi pi-times" className="p-button-sm p-button-danger p-button-raised" onClick={(evt)=>setDialogValues({...dialogValues,openAddEmail:false})}/>
					</Grid>
				</Grid>
			</Dialog>
		);
	}

	const paymentsDialog = () => {
		return(
			<Dialog header={dialogValues.titlePayments} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openPayments:false})} position="right" className="responsive-dialog-med">
				<Grid container spacing={1} style={{"marginLeft":"0px","width":"99.99%"}}>
					{!!((currentInvoice.partial_payments===1)||(dataValues.paymentsList.length===0)) &&
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="field padding-med text-right">
							<Button label="Add payment" icon="pi pi-plus-circle" className="p-button-sm p-button-primary" tooltip="Add payment" style={{}} onClick={(evt) => {handleButton(evt,"add_payment")}}/>
						</Grid>						
					}
					{!!((currentInvoice.partial_payments===0)&&(dataValues.paymentsList.length>0)) &&
						<React.Fragment>
							<Grid item xs={9} sm={9} md={9} lg={9} xl={9} className="field padding-med text-right">
								<p style={{"fontWeight":"600","color":"#FF0000","textAlign":"left","marginTop":"6px"}}>Multiple payments not allowed for this invoice.</p>
							</Grid>						
							<Grid item xs={3} sm={3} md={3} lg={3} xl={3} className="field padding-med text-right">
								<Button label="Add payment" icon="pi pi-plus-circle" className="p-button-sm p-button-primary" tooltip="Add payment" style={{}} disabled={true}/>
							</Grid>						
						</React.Fragment>
					}
				</Grid>
				<DataTable
					value={dataValues.paymentsList}
					scrollable
					scrollHeight="30vh"
					style={{"minHeight":"30vh","marginBottom":"18px"}}
					stripedRows
					resizableColumns
					columnResizeMode="fit"
					emptyMessage={<p style={{"textAlign":"center"}}>No entries found...</p>}
				>
					<Column field="amount" header="Amount" style={{}} body={tableColTemplatePayments}></Column>
					<Column field="currency" header="Currency" style={{}} body={tableColTemplatePayments}></Column>
					<Column field="payment_type" header="Payment type" style={{}} body={tableColTemplatePayments}></Column>
					<Column field="payment_date" header="Date" style={{}} body={tableColTemplatePayments}></Column>
					<Column field="reg_by" header="Registered by" style={{}} body={tableColTemplatePayments}></Column>
					<Column field="buttons" header="" style={{"width":"10%"}} body={tableColTemplatePayments}></Column>
				</DataTable>
			</Dialog>
		);
	}

	const addPaymentsDialog = () => {
		return(
			<Dialog header={dialogValues.titleAddPayments} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openAddPayments:false})} className="responsive-dialog-small">
				<Grid container spacing={1} style={{"marginLeft":"0px","width":"99.99%"}}>
					<Grid item xs={12} sm={12} md={3} lg={3} className="field padding-med">
						<label htmlFor="amount">Amount*</label>
						<InputText name="amount" id="amount" style={{"width":"100%"}} pattern="[0-9.]*" value={inputValuesPayments.amount} onChange={(evt) => handleChangePayments(evt)}/>
					</Grid>					
					<Grid item xs={12} sm={12} md={6} lg={6} className="field padding-med">
						<label htmlFor="">Type*</label>
						<Dropdown name="payment_type" id="payment_type" style={{"width":"100%"}} options={paymentsTypesList} value={inputValuesPayments.payment_type} onChange={(evt) => handleChangePayments(evt)}/>
					</Grid>
					<Grid item xs={12} sm={12} md={3} lg={3} className="field padding-med">
						<label htmlFor="payment_date">Payment date*</label>
						<Calendar value={inputValuesPayments.payment_date} style={{"width":"100%"}} onChange={(evt) => setInputValuesPayments({...inputValuesPayments,payment_date: evt.value})}/>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} className="field padding-med">
						<label htmlFor="details">Payment details</label>
						<InputText name="details" id="details" style={{"width":"100%"}} value={inputValuesPayments.details} onChange={(evt) => handleChangePayments(evt)}/>
					</Grid>					
					<Grid item xs={12} sm={12} md={7} lg={7} xl={7} className="field padding-med text-right">
						<p style={{"color":"#FF0000","fontWeight":"600","textAlign":"left"}}>{inputErrorPayments}</p>
					</Grid>						
					<Grid item xs={12} sm={12} md={5} lg={5} xl={5} className="field padding-med text-right">
						<Button label="Save" icon="pi pi-check-circle" className="p-button-sm p-button-success p-button-raised" style={{"marginRight":"6px"}} disabled={((inputErrorPayments==="")||(inputErrorPayments==="Partial payments not allowed!"))?false:true} onClick={(evt) => handleButton(evt,"save_payment")}/>
						<Button label="Close" icon="pi pi-times" className="p-button-sm p-button-danger p-button-raised" onClick={(evt)=>setDialogValues({...dialogValues,openAddPayments:false})}/>
					</Grid>						
				</Grid>
			</Dialog>
		);
	}

	const confirm = (evt,what,elementData) => {
		if(what==="request_deletion") {
				confirmDialog({
						message: "Are you sure you want to request deletion?",
						header: "Confirm delete request",
						icon: "pi pi-exclamation-triangle",
						accept: () => handleButton(evt,what,elementData),
						reject: () => null,
				});
		}
		if(what==="cancel_request_deletion") {
			confirmDialog({
					message: "Are you sure you want to cancel deletion request?",
					header: "Confirm cancel deletion request",
					icon: "pi pi-exclamation-triangle",
					accept: () => handleButton(evt,what,elementData),
					reject: () => null,
			});
		}
		if(what==="delete_payment") {
			confirmDialog({
					message: "Are you sure you want to delete payment?",
					header: "Confirm delete request",
					icon: "pi pi-exclamation-triangle",
					accept: () => handleButton(evt,what,elementData),
					reject: () => null,
			});
		}
	}

    const rowClass = (row_data) => {
		row_ct++;
		return {
            "invoices_rfd_row": row_data.status==="DELETE_REQUESTED",
            "invoices_deleted_row": row_data.status==="DELETED",
			"p-row-odd": row_ct%4===0
        };
    };

	if(dataOk) {
		return(
			<BlockUI blocked={blockScreen} fullScreen>
				<Grid container spacing={1} style={{"marginLeft":"0px","width":"99.99%"}}>
					{!!(customDates) &&
						<React.Fragment>
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4} className="field padding-min">
								<label htmlFor="filter_name">Filter records</label>
								<InputText name="filter_name" id="filter_name" style={{"width":"100%"}} placeholder="Filter by client..." value={inputValues.filter_name} onChange={(evt) => handleChange(evt)} disabled={(dataValues.invoicesList.length===0?true:false)}/>
							</Grid>
							<Grid item xs={12} sm={4} md={3} lg={2} xl={2} className="field padding-min">
								<label htmlFor="period">Period</label>
								<Dropdown name="period" id="period" style={{"width":"100%"}} options={periodsList} value={inputValues.period} onChange={(evt) => handleChange(evt)}/>
							</Grid>
							<Grid item xs={6} sm={3} md={2} lg={1} xl={1} className="field padding-min">
								<label htmlFor="">Start date</label>
								<Calendar value={inputValues.start_date} style={{"width":"100%"}} onChange={(evt) => setInputValues({...inputValues,start_date: evt.value})}/>
							</Grid>
							<Grid item xs={6} sm={3} md={2} lg={1} xl={1} className="field padding-min">
							<label htmlFor="">Stop date</label>
								<Calendar value={inputValues.stop_date} style={{"width":"100%"}} onChange={(evt) => setInputValues({...inputValues,stop_date: evt.value})}/>
							</Grid>
						</React.Fragment>
					}
					{!!(!customDates) &&
						<React.Fragment>
							<Grid item xs={12} sm={6} md={4} lg={4} xl={4} className="field padding-min">
								<label htmlFor="filter_name">Filter records</label>
								<InputText name="filter_name" id="filter_name" style={{"width":"100%"}} placeholder="Filter by client..." value={inputValues.filter_name} onChange={(evt) => handleChange(evt)} disabled={(dataValues.invoicesList.length===0?true:false)}/>
							</Grid>
							<Grid item xs={12} sm={6} md={3} lg={2} xl={2} className="field padding-min">
								<label htmlFor="period">Period</label>
								<Dropdown name="period" id="period" style={{"width":"100%"}} options={periodsList} value={inputValues.period} onChange={(evt) => handleChange(evt)}/>
							</Grid>
							<Grid item xs={6} sm={3} md={4} lg={2} xl={2} className="field padding-min">
								&nbsp;
							</Grid>
						</React.Fragment>
					}
					{!!(dataValues.invoicesList.length>0) &&
						<React.Fragment>
							<Grid item xs={4} sm={5} md={6} lg={1} xl={1} className="field padding-min text-left">
								<h5 style={{"margin-top":"21px","padding":"12px","color":"#000000"}}>{invoicesList.length} record(s)</h5>
							</Grid>						
							<Grid item xs={8} sm={7} md={6} lg={3} xl={3} className="field padding-min text-right" style={{"marginTop":"24px","marginBottom":"21px"}}>
								<Button label="Update list" icon="pi pi-refresh" className="p-button-sm p-button-raised" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"update")}/>
								<Button label="Export .xls" icon="pi pi-file-excel" className="p-button-sm p-button-raised" style={{}} onClick={(evt) => handleButton(evt,"export_xls")} disabled={(invoicesList.length===0?true:false)}/>
							</Grid>						
						</React.Fragment>
					}
					{!!(dataValues.invoicesList.length===0) &&
						<React.Fragment>
							<Grid item xs={12} sm={12} md={12} lg={4} xl={4} className="field padding-min text-right" style={{"marginTop":"24px","marginBottom":"21px"}}>
								<Button label="Update list" icon="pi pi-refresh" className="p-button-sm p-button-raised" style={{}} onClick={(evt) => handleButton(evt,"update")}/>
							</Grid>						
						</React.Fragment>
					}
				</Grid>
				<React.Fragment>
					<Box sx={{ display: { xs: "block", sm: "block", md: "block", lg: "block", xl: "block" } }}>
						<div className="card" style={{"marginTop":"3px"}}>
							<DataTable
								value={invoicesList}
								scrollable
								scrollHeight="60vh"
								style={{"minHeight":"63vh"}}
								stripedRows
								resizableColumns
								columnResizeMode="fit"
								expandedRows={expandedRows}
								onRowToggle={(evt) => setExpandedRows(evt.data)}
								rowExpansionTemplate={rowExpansionTemplate}
								rowClassName={rowClass}
								emptyMessage={<p style={{"textAlign":"center"}}>No entries found...</p>}
							>
								<Column expander={true} style={{"width":"3rem"}}></Column>
								<Column field="number_date" header="No. / date" style={{}} body={tableColTemplate} sortable></Column>
								<Column field="client_name" header="Client name" style={{}} body={tableColTemplate} sortable></Column>
								<Column field="issuer_name" header="Issued by" style={{}} body={tableColTemplate} sortable></Column>
								<Column field="subtotal" header="Subtotal" style={{}} body={tableColTemplate}></Column>
								<Column field="vat" header="VAT" style={{}} body={tableColTemplate}></Column>
								<Column field="total" header="Total" style={{}} body={tableColTemplate}></Column>
								<Column field="currency" header="Currency" style={{}} body={tableColTemplate} sortable></Column>
								<Column field="status" header="Status" style={{}} body={tableColTemplate} sortable></Column>
								<Column field="payed_amount" header="Payed amount" style={{}} body={tableColTemplate} sortable></Column>
								<Column field="sended" header="Sent" style={{}} body={tableColTemplate} sortable></Column>
								<Column field="buttons" header="" style={{}} body={tableColTemplate}></Column>
							</DataTable>
						</div>
					</Box>
				</React.Fragment>
				<ConfirmDialog />
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
				{dialogValues.openViewInvoice && viewInvoiceDialog()}
				{dialogValues.openAddPhone && addPhoneDialog()}
				{dialogValues.openAddEmail && addEmailDialog()}
				{dialogValues.openPayments && paymentsDialog()}
				{dialogValues.openAddPayments && addPaymentsDialog()}
			</BlockUI>
		);
	}else{
		return(
			<React.Fragment>
				<LinearProgress />
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
			</React.Fragment>
		);
	}
};

export default Invoices;
