import React from "react";
import {useState,useEffect} from "react";
import {useLocation,useNavigate} from "react-router-dom";
import {BlockUI} from "primereact/blockui";
import Grid from "@mui/material/Grid";
import {InputText} from "primereact/inputtext";
import {AutoComplete} from "primereact/autocomplete";
import {Checkbox} from "primereact/checkbox";
import {ScrollPanel} from "primereact/scrollpanel";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {ConfirmDialog} from "primereact/confirmdialog";
import {confirmDialog} from "primereact/confirmdialog";
import {Divider} from "primereact/divider";
import {Card} from "primereact/card";
import {Dropdown} from "primereact/dropdown";
import {InputTextarea} from "primereact/inputtextarea";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import LinearProgress from "@mui/material/LinearProgress";
import {Box} from "@mui/system";
import Message from "../Helpers/Message.js";
import {fetch_url_post,fetch_download} from "../../Sources/js/app.js";

let oper="";

let defaultDataValues={
	customerDetails: {},
	customerClientsList: [],
	customerItemsList: [],
};

let defaultDialogValues={
	openAddEdit: false,
	titleAddEdit: "",
	openAddPhone: false,
	titleAddPhone: "",
	openAddEmail: false,
	titleAddEmail: "",
	openBrowseInventory: false,
	titleBrowseInventory: "",
	openPriceAndQuantity: false,
	titlePriceAndQuantity: "",
	openBarcode: false,
	titleBarcode: "",	
	openInsufficientStock: false,
	titleInsufficientStock: "",
};

let defaultInputValues={
	id: null,
	name: "",
	address: "",
	phone: "",
	email: "",
	trn: "",
	notes: "",
	number: "",
};

let defaultInputValuesItems={
	item_id: 0,
	quantity: "",
	unit_price: "",
	discount: 0,
};

let walkin_client={
	name: "WALK-IN-CLIENT",
	address: "",
	phone: "",
	email: "",
	trn: "",
}

let defaultTotals={
	subtotal: 0,
	vat: 0,
	total: 0,
}

let defaultInvoiceSerials={
	invoiceId: null,
	invoiceNo: "______",
	invoiceDate: null,
	invoiceHash: "",
}

let defaultInputValuesFilter={
	filter_description: "",
};

const Invoice = (props) => {

	var timerId=null;
	const [dataOk, setDataOk] = useState(true);
	const [inputValues, setInputValues] = useState(defaultInputValues);
	const [blockScreen, setBlockScreen] = useState(false);
	const [messageText, setMessageText] = useState(null);
	const [dataValues, setDataValues] = useState(defaultDataValues);
	const [selectedClient, setSelectedClient] = useState(walkin_client);
	const [filteredClientsList, setFilteredClientsList] = useState([]);
	const [dialogValues, setDialogValues] = useState(defaultDialogValues);
	const [walkin, setWalkin] = useState(true);
	const [selectedItem, setSelectedItem] = useState(null);
	const [itemsList, setItemsList] = useState([]);
	const [inputValuesItems, setInputValuesItems] = useState(defaultInputValuesItems);
	const [totals, setTotals] = useState(defaultTotals);
    const [invoiceSerials, setInvoiceSerials] = useState(defaultInvoiceSerials);
	const [invoiceState, setInvoiceState] = useState("open");
	const [lockPrice, setLockPrice] = useState(false);
	const [wasSent, setWasSent] = useState(false);
	const [productsGroups, setProductsGroups] = useState([]);
	const [filteredProductsGroups, setFilteredProductsGroups] = useState([]);
	const [currentCategory, setCurrentCategory] = useState("both");	
	const [currentBrowsing, setCurrentBrowsing] = useState("groups");
	const [filteredBrowseItems, setFilteredBrowseItems] = useState(true);
	const [currency, setCurency] = useState("DHS");
	const [barcodeItem, setBarcodeItem] = useState(null);
	const [inputValuesFilter, setInputValuesFilter] = useState(defaultInputValuesFilter);
	const [currentBrowsingGroup, setCurrentBrowsingGroup] = useState({});
	const [discountsList, setDiscountsList] = useState([]);
	const [neededQuantities, setNeededQuantities] = useState([]);
	const [xeRate, setXeRate] = useState(1);
    const location = useLocation();
    const navigate = useNavigate();

	let currencyList=[
		{
			label: "DHS",value: "DHS",xe_rate: 1,
		},
		{
			label: "USD",value: "USD",xe_rate: (parseFloat(props.session.xe_rate_usd)?parseFloat(props.session.xe_rate_usd):1),
		},
		{
			label: "EUR",value: "EUR",xe_rate: (parseFloat(props.session.xe_rate_eur)?parseFloat(props.session.xe_rate_eur):1),
		},
		{
			label: "GBP",value: "GBP",xe_rate: (parseFloat(props.session.xe_rate_gbp)?parseFloat(props.session.xe_rate_gbp):1),
		},
	];

	const handleChange = evt => {
		const {name, value} = evt.target;
		if(name==="phone") {
			if(!evt.target.validity.valid) {
				setMessageText("Error: Only digits are allowed!");							
				return false;
			}
		}
		setInputValues({...inputValues,[name]:value});
	}

	const handleChangeFormatted = (evt) => {
		const {name, value} = evt.target;
		if(name==="quantity") {
			if(evt.target.validity.valid) {
				setInputValuesItems({...inputValuesItems,[name]:value});
			}else{
				window.alert("Only integer values are allowed!");
				return false;								
			}
		}
		if(name==="unit_price") {
			if(evt.target.validity.valid) {
				setInputValuesItems({...inputValuesItems,[name]:value});
			}else{
				window.alert("Only numbers are allowed!");
				return false;								
			}
		}
		if(name==="currency") {
			setCurency(value);
			setXeRate(currencyList.find(currency => currency.value===value)?.xe_rate);
		}
		if(name==="discount") {
			setInputValuesItems({...inputValuesItems,[name]:value});
		}
		if(name==="xe_rate") {
			if(evt.target.validity.valid) {
				setXeRate(value);
			}else{
				window.alert("Only numbers are allowed!");
				return false;								
			}
		}
	}

	const handleEnter = (evt,where) => {
		if(where==="barcode") {
			if(evt.key==="Enter") {
				handleButton(evt,"search_barcode",1)
			}
		}
	}

	const handleChangeFilter = evt => {
		const {name, value} = evt.target;
		if(name==="filter_description") {
			setFilteredBrowseItems(dataValues.customerItemsList.filter((item)=>((item.group_id===currentBrowsingGroup.group_id)))
															   .filter((item)=>((item.description.toLowerCase().indexOf(value.toLowerCase())>-1)||(value==="")))
			);
		}
		setInputValuesFilter({...inputValuesFilter,[name]:value});
	}

	const handleItemChange = evt => {
		if(evt.value) {
			setSelectedItem(evt.value);
			//if the user are able to modify prices we search the item on list, else we lock price and copy list price
			if(props.session.lock_prices===0) {
				//if we have item already on list, we lock the price
				let found=false;
				setInputValuesItems({...inputValuesItems,unit_price:evt.value.list_price});
				itemsList.forEach(c_item => {
					if(c_item.sku===evt.value.sku) {
						found=true;
						setInputValuesItems({...inputValuesItems,unit_price:c_item.list_price});
					}
				});		
				setLockPrice(found);
				if(!found) {
					setCurency(evt.value.currency);					
					setXeRate(currencyList.find(currency => currency.value===evt.value.currency)?.xe_rate);
				}
			}else{
				setInputValuesItems({...inputValuesItems,unit_price:evt.value.list_price});
				setCurency(evt.value.currency);
				setXeRate(currencyList.find(currency => currency.value===evt.value.currency)?.xe_rate);
			}
			setDialogValues({...dialogValues,openPriceAndQuantity: true,titlePriceAndQuantity: ""});			
		}
	}

	const handleButton = (evt,what,elementData) => {
		if(what==="add_client") {
			setInputValues(defaultInputValues);
			setDialogValues({...dialogValues,openAddEdit: true,titleAddEdit: "Add new client"});
		}
		if(what==="edit_client") {
			if(!elementData) {
				setMessageText("Error: Please select a client!");			
				return false;					
			}
			setInputValues(elementData);
			setDialogValues({...dialogValues,openAddEdit: true,titleAddEdit: "Edit client"});
		}
		if(what==="save_client") {
			document.querySelectorAll("input").forEach((element)=>element.classList.remove("required_input_error"));
			if(inputValues.name==="") {
				setMessageText("Error: Please fill name!");				
				document.getElementById("name").classList.add("required_input_error");
				return false;
			}
			if(inputValues.address==="") {
				setMessageText("Error: Please fill address!");				
				document.getElementById("address").classList.add("required_input_error");
				return false;
			}
			let url="saveCustomerClient";
			url+="?session_id="+props.session.sessId;
			oper="save_client";
			let data={...inputValues};
			timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
		}
		if(what==="add_item") {
			document.querySelectorAll("input").forEach((element)=>element.classList.remove("required_input_error"));
			if(selectedItem==="") {
				setMessageText("Error: Please select an item!");				
				document.getElementById("item").classList.add("required_input_error");
				return false;
			}
			if(inputValuesItems.quantity==="") {
				setMessageText("Error: Please fill quantity!");				
				document.getElementById("quantity").classList.add("required_input_error");
				return false;
			}
			if(inputValuesItems.quantity==="0") {
				setMessageText("Error: Quantity cannot be zero!");				
				document.getElementById("quantity").classList.add("required_input_error");
				return false;
			}
			if(inputValuesItems.unit_price==="") {
				setMessageText("Error: Please fill price!");				
				document.getElementById("unit_price").classList.add("required_input_error");
				return false;
			}
			if((currency!=="DHS")&&(!xeRate)) {
				setMessageText("Error: Please fill exchange rate!");				
				document.getElementById("xe_rate").classList.add("required_input_error");
				return false;				
			}
			let currentRow={};
			let _itemsList=[...itemsList];
			let found=false;
			//lets arrange the numbers
			let _quantity=parseFloat(inputValuesItems.quantity).toFixed(selectedItem.decimal_places);
			let _list_price=parseFloat(inputValuesItems.unit_price).toFixed(2);
			let _discount=parseFloat(inputValuesItems.discount).toFixed(2);
			let _unit_price=_list_price*(1-_discount).toFixed(2);
			_itemsList.forEach(c_item => {
				//lets group items by sku
				if((c_item.sku===selectedItem.sku)&&(c_item.unit_price===_unit_price)) {
					c_item.quantity=((100*c_item.quantity)+(100*_quantity))/100;
					//if we have VAT we must calculate
					if(dataValues.customerDetails.vat_registered===1) {
						c_item.price=((100*c_item.unit_price)*(100*c_item.quantity))/10000;
						//the VAT will be applied to the discounted price (price)
						c_item.vat=((100*c_item.price)*(100*c_item.vat_level))/10000;
						c_item.line_total=((100*c_item.price)+(100*c_item.vat))/100;
					}else{
						c_item.price=((100*c_item.unit_price)*(100*c_item.quantity))/10000;
						c_item.vat=0;
						c_item.line_total=c_item.price;
					}
					found=true;
				}
			});
			if(!found) {
				currentRow.id=selectedItem.id;
				currentRow.description=selectedItem.description;
				currentRow.vat_level=selectedItem.vat_level;
				currentRow.quantity=_quantity;
				currentRow.unit_price=_unit_price;
				currentRow.list_price=_list_price;
				currentRow.currency=currency;
				currentRow.abbr=selectedItem.abbreviation;
				currentRow.discount=_discount;
				currentRow.sku=selectedItem.sku;
				//if we have VAT we must calculate
				if(dataValues.customerDetails.vat_registered===1) {
					currentRow.price=((100*_unit_price)*(100*_quantity))/10000;
					currentRow.list_price=(100*_list_price)/100;
					//the VAT will be applied to the discounted price (price)
					currentRow.vat=((100*currentRow.price)*(100*currentRow.vat_level))/10000;
					currentRow.line_total=((100*currentRow.price)+(100*currentRow.vat))/100;
					currentRow.line_total=parseFloat(currentRow.line_total).toFixed(2);
				}else{
					currentRow.price=((100*_unit_price)*(100*_quantity))/10000;
					currentRow.price=parseFloat(currentRow.price).toFixed(2);
					currentRow.vat=0;
					currentRow.line_total=currentRow.price;
				}
				setItemsList([...itemsList,currentRow]);
				//adjust totals
				let _totals={...totals};
				_totals.subtotal+=(100*currentRow.price)/100;
				_totals.vat+=(100*currentRow.vat)/100;
				_totals.total+=(100*currentRow.line_total)/100;
				setTotals(_totals);
			}else{
				//adjust totals
				let _totals={...defaultTotals};
				_itemsList.forEach(c_item => {
					_totals.subtotal+=(100*c_item.price)/100;
					_totals.vat+=(100*c_item.vat)/100;
					_totals.total+=(100*c_item.line_total)/100;					
				});
				setItemsList(_itemsList);
				setTotals(_totals);
			}
			setInputValuesItems({...defaultInputValuesItems});
			setSelectedItem(null);
			setDialogValues({...dialogValues,openPriceAndQuantity: false,titlePriceAndQuantity: "",
											openBrowseInventory: false,titleBrowseInventory:""
			});			
			setBarcodeItem(null);
			setInputValues({...inputValues,barcode: ""});
		}
		if(what==="delete_item") {
			let _itemsList=[...itemsList];
			let _totals={...defaultTotals};
			_itemsList.splice(elementData,1);
			_itemsList.forEach(c_item => {
				_totals.subtotal+=(100*c_item.price)/100;
				_totals.vat+=(100*c_item.vat)/100;
				_totals.total+=(100*c_item.line_total)/100;					
			});
			setItemsList(_itemsList);
			setTotals(_totals);
		}
		if(what==="reset") {
			setInputValues(defaultInputValues);
			setSelectedClient(walkin_client);
			setWalkin(true);
			setSelectedItem(null);
			setItemsList([]);
			setInputValuesItems(defaultInputValuesItems);
			setTotals(defaultTotals);
			setInvoiceSerials(defaultInvoiceSerials);
			setFilteredProductsGroups(productsGroups);
			setCurrentCategory(elementData);
			setCurrentBrowsing("groups");
			setCurrentCategory("both");
		}
		if(what==="new") {
			setInputValues(defaultInputValues);
			setSelectedClient(walkin_client);
			setWalkin(true);
			setSelectedItem(null);
			setItemsList([]);
			setInputValuesItems(defaultInputValuesItems);
			setTotals(defaultTotals);
			setInvoiceSerials(defaultInvoiceSerials);
			setInvoiceState("open");
			setWasSent(false);
		}
        if(what==="save") {
			document.querySelectorAll("input").forEach((element)=>element.classList.remove("required_input_error"));
			if((props.session.invoice_numbering!=="AUTOMATIC")&&(inputValues.number==="")) {
				setMessageText("Error: Please enter invoice number!");
				document.getElementById("number").classList.add("required_input_error");
				return false;
			}
			let url="saveInvoice";
			url+="?session_id="+props.session.sessId;
			oper="save";
            let _totals={};
            _totals.subtotal=totals.subtotal.toFixed(2);
            _totals.total=totals.total.toFixed(2);            
            _totals.vat=totals.vat.toFixed(2);
            let data={
                id: (invoiceSerials.invoiceId?invoiceSerials.invoiceId:null),
                client: selectedClient,
                items: itemsList,
                totals: _totals,
				currency: currency,
				xe_rate: (currency==="DHS"?1:xeRate),
                observations: inputValues.notes,
				number: (props.session.invoice_numbering!=="AUTOMATIC"?inputValues.number:null),
            };
			timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
        }
        if(what==="edit") {
			setInvoiceState("open");
        }
		if(what==="print") {
            let _totals={};
            _totals.subtotal=totals.subtotal.toFixed(2);
            _totals.total=totals.total.toFixed(2);            
            _totals.vat=totals.vat.toFixed(2);
            let data={
				issuer: dataValues.customerDetails,
                client: selectedClient,
                items: itemsList,
                totals: _totals,
                observations: inputValues.notes,
				serials: invoiceSerials,
            };			
			setWasSent(true);
			window["sunmi_print"](JSON.stringify(data));
		}
		if(what==="whatsapp") {
			setInputValues({...inputValues,phone:selectedClient.phone});
			setDialogValues({...dialogValues,openAddPhone: true,titleAddPhone: "Enter phone number"});			
		}
		if(what==="send_whatsapp") {
			let ok=0;
			//first check if we have a valid phone number
			let phone=(elementData===0?selectedClient.phone:inputValues.phone);
			phone=phone.trim();
			if(phone.length) {
				//if the phone starts with 00 remove them
				phone=phone.replace(/^0+/,"");
				//after that check if length>10
				if(phone.length>10) {
					ok=1;
				}
			}else{
				if(elementData===1) {
					setMessageText("Error: Please enter phone number!");			
					return false;					
				}
			}
			if(ok===1) {
				let text="Dear customer,\n\nHere you can download the invoice:\n\n https://clients.mfaconsultancyllc.com/api3/cInvoices?hash="+invoiceSerials.invoiceHash+"&ctls=true&app=w"+"\n\nThank you for choosing us.\n";
				let location="https://wa.me/"+phone+"?text="+escape(text);
				let url="setInvoiceAsSent";
				url+="?session_id="+props.session.sessId;
				let data={
					id: invoiceSerials.invoiceId,
				};
				fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
				document.location=(location);
				setWasSent(true);
				setDialogValues(defaultDialogValues);
			}else{
				setDialogValues({...dialogValues,openAddPhone: true,titleAddPhone: "Enter phone number"});
				if(elementData===1) {
					setMessageText("Error: Invalid telephone number!");								
				}
			}
		}
		if(what==="email") {
			setInputValues({...inputValues,email:selectedClient.email});
			setDialogValues({...dialogValues,openAddEmail: true,titleAddEmail: "Enter email address"});			
		}
		if(what==="send_email") {
			let ok=0;
			//first check if we have a valid email address
			let email=(elementData===0?selectedClient.email:inputValues.email);
			email=email.trim();
			if(email.length) {
				console.log("We have email: "+email);
				let mail_format = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
				if(email.match(mail_format)) {
					ok=1;
				}
			}else{
				if(elementData===1) {
					setMessageText("Error: Please enter email address!");			
					return false;					
				}
			}
			if(ok===1) {
				if(invoiceSerials.invoiceId) {
					let url="emailInvoice";
					url+="?session_id="+props.session.sessId;
					oper="email_or_pdf";
					let data={
						id: invoiceSerials.invoiceId,
						to: email,
						customer_name: props.session.customer_name,
					};
					timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
					setDialogValues(defaultDialogValues);
				}else{
					setMessageText("Error: Invalid invoice details!");													
				}
			}else{
				setDialogValues({...dialogValues,openAddEmail: true,titleAddEmail: "Enter email address"});
				if(elementData===1) {
					setMessageText("Error: Invalid email address!");								
				}
			}
		}
		if(what==="pdf") {
			if(invoiceSerials.invoiceId) {			
				let url="getPdfInvoice";
				url+="?session_id="+props.session.sessId;
				oper="email_or_pdf";
				let data={
					id: invoiceSerials.invoiceId,
				};
				fetch_download(url,data,"Invoice_"+invoiceSerials.invoiceNo+".pdf",success_handler);
			}else{
				setMessageText("Error: Invalid invoice details!");													
			}			
		}
		if(what==="browse_inventory") {
			if(filteredProductsGroups.length===0) {
				let groups=[];
				let groups_ids=[];
				dataValues.customerItemsList.forEach((element) => {
					let tmp={
						group_name: element.group_name,
						group_id: element.group_id,
						category: element.category,
					}
					if(!groups_ids.includes(element.group_id)) {
						groups.push(tmp);
						groups_ids.push(element.group_id);
					}
				})
				setProductsGroups(groups);
				setFilteredProductsGroups(groups);
			}
			setDialogValues({...dialogValues,openBrowseInventory: true,titleBrowseInventory: "Select an item"});
		}
		if(what==="filter_groups") {
			if(elementData!=="both") {
				setFilteredProductsGroups(productsGroups.filter((group)=>((group.category.toLowerCase()===elementData))));
			}else{
				setFilteredProductsGroups(productsGroups);
			}
			setCurrentCategory(elementData);
		}
		if(what==="browse_items") {
			setCurrentBrowsing("items");
			setCurrentBrowsingGroup(elementData);
			setFilteredBrowseItems(dataValues.customerItemsList.filter((item)=>((item.group_id===elementData.group_id)))
															   .filter((item)=>((item.description.toLowerCase().indexOf(inputValuesFilter.filter_description.toLowerCase())>-1)||(inputValuesFilter.filter_description==="")))
			);
		}
		if(what==="browse_groups") {
			setCurrentBrowsing("groups");
		}
		if(what==="select_item_from_browse") {
			setSelectedItem(elementData);
			if(props.session.lock_prices===0) {
				//if we have item already on list, we lock the price
				let found=false;
				setInputValuesItems({...inputValuesItems,unit_price:elementData.list_price});
				itemsList.forEach(c_item => {
					if(c_item.sku===elementData.sku) {
						found=true;
						setInputValuesItems({...inputValuesItems,unit_price:c_item.list_price});
					}
				});		
				setLockPrice(found);
				if(!found) {
					setCurency(elementData.currency);					
					setXeRate(currencyList.find(currency => currency.value===elementData.currency)?.xe_rate);
				}
			}else{
				setInputValuesItems({...inputValuesItems,unit_price:elementData.list_price});
				setCurency(elementData.currency);
				setXeRate(currencyList.find(currency => currency.value===elementData.currency)?.xe_rate);
			}
			setDialogValues({...dialogValues,openPriceAndQuantity: true,titlePriceAndQuantity: "Quantity and price"});			
		}
		if(what==="enter_barcode") {
			setBarcodeItem(null);
			setInputValues({...inputValues,barcode: ""});
			setDialogValues({...dialogValues,openBarcode: true,titleBarcode: "Enter barcode"});
		}
        if(what==="search_barcode") {
			document.querySelectorAll("input").forEach((element)=>element.classList.remove("required_input_error"));
			if(!inputValues.barcode) {
				setMessageText("Error: Please enter barcode!");
				document.getElementById("barcode").classList.add("required_input_error");
				return false;
			}
			let url="getItemByBarcode";
			url+="?session_id="+props.session.sessId;
			oper="get_item_by_barcode";
            let data={
                barcode: inputValues.barcode,
            };
			timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
        }
	}

	const success_handler = (result) => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
			setMessageText("");
		}
		if(result.ok===0) {
			setMessageText("Error: "+result.error);
			if(result.error==="Insufficient stock!") {
				let insufficient_items=[];
				let i=0;
				for(let insufficient_sku of result.insufficient_stock_list.split(",")) {
//					console.log("Search for: "+insufficient_sku);
					for(let item of itemsList) {
//						console.log("Compare with: "+item.sku);
						if(item.sku==insufficient_sku) {
							let insufficient_item={};
//							console.log("found with i: "+i);
							insufficient_item.requested_quantity=item.quantity;
							insufficient_item.description=item.description;
							insufficient_item.existing_quantity=result.insufficient_stock_quantities.split(",")[i];
							insufficient_items.push(insufficient_item);
						}
					}
					i++;
				}
//				console.log(insufficient_items);
				setDialogValues({...dialogValues,openInsufficientStock: true,titleInsufficientStock: "Insufficient stock!"});
				setNeededQuantities(insufficient_items);
			}
			return false;
		}
		if(result.is_auth===0) {
			navigate("/login");			
			return false;
		}
		if(result.message) {
			setMessageText(result.message);			
		}
		if(oper==="init") {
			setDataValues({...dataValues,customerDetails:(result.customerDetails?result.customerDetails:{}),
							customerClientsList:(result.customerClientsList?result.customerClientsList:[]),
							customerItemsList:(result.customerItemsList?result.customerItemsList:[])
			});
			//set discounts list
			let tmp_discounts=[];
			for(let i=0;i<=props.session.max_discount;i++) {
				let c_discount={
					label: i.toString()+"%",
					value: i/100,
				}
				tmp_discounts.push(c_discount);
			}
			setDiscountsList(tmp_discounts);
			setDataOk(true);
			if(props.session.walk_in===0) {
				setWalkin(false);
				setSelectedClient(null);			
			}
			oper="";
		}
		if(oper==="save_client") {
			setDataValues({...dataValues,customerClientsList:(result.customerClientsList?result.customerClientsList:[])});
			setDialogValues(defaultDialogValues);
			result.customerClientsList.forEach(client => {
				if(client.id===result.new_id) {
					setSelectedClient(client);
				}
			});
			oper="";
		}
        if(oper==="save") {
			setInvoiceSerials({...invoiceSerials,invoiceId:result.new_id,
												invoiceNo:result.new_number,
												invoiceDate:result.new_date,
												invoiceHash:result.new_hash
			});
			setInvoiceState("close");
			oper=""
        }
		if(oper==="email_or_pdf") {
			setWasSent(true);
			oper="";
		}
		if(oper==="get_item_by_barcode") {
			setBarcodeItem(result.data);
			oper="";
		}
	}

	const fail_handler = (error) => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
		}
		console.log(error);
		setMessageText("Connection error please try again later!");
	}

	const wait_handler = () => {
		setBlockScreen(true);
		setMessageText("Operation is in progress. Please wait...");
	}

	useEffect(() => {
		if(props.session.sessId) {
			oper="init";
			let url="initInvoice";
			url+="?session_id="+props.session.sessId;
			let data={
				user_id: (props.user?props.user.id:null),
			};
			fetch_url_post(url,data,success_handler,fail_handler);
		}else{
			navigate("/login");
		}
	},[]);

	const toggleWalkIn = (evt) => {
		if(evt.checked) {
			setSelectedClient({...walkin_client});
		}else{
			setSelectedClient(null);			
		}
		setWalkin(evt.checked);
	}

    const searchClients = (evt) => {
        setTimeout(() => {
            let _filteredClientsList;
            if (!evt.query.trim().length) {
                _filteredClientsList = [...dataValues.customerClientsList];
            }else{
                _filteredClientsList = dataValues.customerClientsList.filter((client) => {
                    return client.name.toLowerCase().startsWith(evt.query.toLowerCase());
                });
            }
            setFilteredClientsList(_filteredClientsList);
        }, 250);
    }

	const addEditDialog = () => {
		return(
			<Dialog header={dialogValues.titleAddEdit} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openAddEdit:false})} className="responsive-dialog-med">
				<Grid container spacing={1} style={{"paddingTop":"12px"}}>
					<Grid item xs={12} sm={12} md={12} lg={6} className="field padding-med">
						{!!(inputValues.id) &&
							<React.Fragment>
								<label htmlFor="name">Name* (cannot be changed)</label>
								<InputText name="name" id="name" style={{"width":"100%"}} value={inputValues.name} disabled={true}/>
							</React.Fragment>
						}
						{!!(!inputValues.id) &&
							<React.Fragment>
								<label htmlFor="name">Name*</label>
								<InputText name="name" id="name" style={{"width":"100%"}} value={inputValues.name} onChange={(evt) => handleChange(evt)}/>
							</React.Fragment>
						}
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={6} className="field padding-med">
						<label htmlFor="address">Address*</label>
						<InputText name="address" id="address" style={{"width":"100%"}} value={inputValues.address} onChange={(evt) => handleChange(evt)}/>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={4} className="field padding-med">
						<label htmlFor="phone">Phone (intl. format with 00)</label>
						<InputText name="phone" id="phone" pattern="[0-9]*" inputmode="numeric" style={{"width":"100%"}} value={inputValues.phone} onChange={(evt) => handleChange(evt)}/>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={6} className="field padding-med">
						<label htmlFor="email">Email</label>
						<InputText name="email" id="email" style={{"width":"100%"}} value={inputValues.email} onChange={(evt) => handleChange(evt)}/>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={2} className="field padding-med">
						<label htmlFor="trn">TRN</label>
						<InputText name="trn" id="trn" style={{"width":"100%"}} value={inputValues.trn} onChange={(evt) => handleChange(evt)}/>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} style={{"textAlign":"right","marginTop":"12px"}}>
						<Button label="Save" icon="pi pi-check-circle" className="p-button-sm p-button-success p-button-raised" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"save_client")}/>
						<Button label="Close" icon="pi pi-times" className="p-button-sm p-button-danger p-button-raised" onClick={(evt)=>setDialogValues({...dialogValues,openAddEdit:false})}/>
					</Grid>
				</Grid>
			</Dialog>
		);
	}

	const addPhoneDialog = () => {
		return(
			<Dialog header={dialogValues.titleAddPhone} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openAddPhone:false})} className="responsive-dialog-small">
				<Grid container spacing={1} style={{"paddingTop":"12px"}}>
					<Grid item xs={12} sm={12} md={12} lg={12} className="field padding-med">
						<label htmlFor="phone">Phone (intl. format with 00)</label>
						<InputText name="phone" id="phone" pattern="[0-9]*" inputmode="numeric" style={{"width":"100%"}} value={inputValues.phone} onChange={(evt) => handleChange(evt)}/>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} style={{"textAlign":"right","marginTop":"12px"}}>
						<Button label="Send" icon="pi pi-whatsapp" className="p-button-sm p-button-success p-button-raised" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"send_whatsapp",1)}/>
						<Button label="Close" icon="pi pi-times" className="p-button-sm p-button-danger p-button-raised" onClick={(evt)=>setDialogValues({...dialogValues,openAddPhone:false})}/>
					</Grid>
				</Grid>
			</Dialog>
		);
	}

	const addEmailDialog = () => {
		return(
			<Dialog header={dialogValues.titleAddEmail} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openAddEmail:false})} className="responsive-dialog-small">
				<Grid container spacing={1} style={{"paddingTop":"12px"}}>
					<Grid item xs={12} sm={12} md={12} lg={12} className="field padding-med">
						<label htmlFor="email">Email address</label>
						<InputText name="email" id="phone" style={{"width":"100%"}} value={inputValues.email} onChange={(evt) => handleChange(evt)}/>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} style={{"textAlign":"right","marginTop":"12px"}}>
						<Button label="Send" icon="pi pi-envelope" className="p-button-sm p-button-success p-button-raised" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"send_email",1)}/>
						<Button label="Close" icon="pi pi-times" className="p-button-sm p-button-danger p-button-raised" onClick={(evt)=>setDialogValues({...dialogValues,openAddEmail:false})}/>
					</Grid>
				</Grid>
			</Dialog>
		);
	}

	const browseInventoryDialog = () => {
		return(
			<Dialog header={dialogValues.titleBrowseInventory} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openBrowseInventory:false})} className="responsive-dialog-big" contentStyle={{"minHeight":"60vh"}}>
				<Grid container spacing={1} style={{"paddingTop":"12px"}}>
					{!!(currentBrowsing==="groups") &&
						<React.Fragment>
							<Grid item xs={4} sm={4} md={4} className="field padding-min" style={{"marginBottom":"24px"}}>
								<Card className={(currentCategory==="goods"?"groups_list_sel":"groups_list_sel groups_list_sel_white")} onClick={(evt) => handleButton(evt,"filter_groups","goods")}>
									<span className="groups_list_name_sel">
										GOODS
									</span>
								</Card>
							</Grid>						
							<Grid item xs={4} sm={4} md={4} className="field padding-min" style={{"marginBottom":"24px"}}>
								<Card className={(currentCategory==="services"?"groups_list_sel":"groups_list_sel groups_list_sel_white")} onClick={(evt) => handleButton(evt,"filter_groups","services")}>
									<span className="groups_list_name_sel">
										SERVICES
									</span>
								</Card>
							</Grid>						
							<Grid item xs={4} sm={4} md={4} className="field padding-min" style={{"marginBottom":"24px"}}>
								<Card className={(currentCategory==="both"?"groups_list_sel":"groups_list_sel groups_list_sel_white")} onClick={(evt) => handleButton(evt,"filter_groups","both")}>
									<span className="groups_list_name_sel">
										BOTH
									</span>
								</Card>
							</Grid>						
							{!!filteredProductsGroups.length>0 && filteredProductsGroups.map((group,index)=> 
								<Grid item xs={6} sm={6} md={4} key={index} className="field padding-min">
									<Card className="groups_list" onClick={(evt) => handleButton(evt,"browse_items",group)}>
										<span className="groups_list_name">
											{group.group_name}
										</span>
									</Card>
								</Grid>						
							)}
							{!(!!productsGroups.length>0) && 
								<Grid item xs={6} sm={6} md={4} className="field padding-min">
									<p>There are no products or services.</p>
								</Grid>						
							}
						</React.Fragment>
					}
					{!!(currentBrowsing==="items") &&
						<React.Fragment>
							<Grid item xs={12} sm={12} md={12} className="field padding-min" style={{"marginBottom":"15px"}}>
								<Card className="groups_list_sel" onClick={(evt) => handleButton(evt,"browse_groups")}>
									<span className="groups_list_name_sel">
										Back to groups
									</span>
								</Card>
							</Grid>						
							<Grid item xs={8} sm={8} md={8} className="field padding-min align_center" style={{"marginBottom":"3px"}}>
								<InputText name="filter_description" id="filter_description" style={{"width":"98%"}} value={inputValuesFilter.filter_description} placeholder="Filter items list..." onChange={(evt) => handleChangeFilter(evt)}/>							
							</Grid>						
							<Grid item xs={4} sm={4} md={4} className="field padding-min align_center" style={{"marginBottom":"12px"}}>
								<span className="groups_list_name" style={{}}>Found {filteredBrowseItems.length} item(s).</span>
							</Grid>						
							<Grid item xs={12} sm={12} md={12} className="field padding-min" style={{"marginBottom":"12px"}}>
								<ScrollPanel style={{"width":"100%","height":"64vh"}}>
									<Grid container spacing={1} style={{}}>
										{!!filteredBrowseItems.length>0 && filteredBrowseItems.map((item,index)=> 
											<Grid item xs={6} sm={6} md={4} key={index} className="field padding-min">
												<Card className="groups_list" onClick={(evt) => handleButton(evt,"select_item_from_browse",item)}>
													<span className="groups_list_name">
														{item.description}
													</span>
												</Card>
											</Grid>						
										)}
									</Grid>
								</ScrollPanel>
							</Grid>						
							{!(!!productsGroups.length>0) && 
								<Grid item xs={6} sm={6} md={4} className="field padding-min">
									<p>There are no products or services.</p>
								</Grid>						
							}
						</React.Fragment>
					}
				</Grid>
			</Dialog>
		);
	}

	const addPriceAndQuantityDialog = () => {
		return(
			<Dialog header={dialogValues.titlePriceAndQuantity} visible={true} onHide={(evt)=>{setDialogValues({...dialogValues,openPriceAndQuantity:false}); setSelectedItem({})}} className="responsive-dialog-small-small">
				{!!((selectedItem)&&(selectedClient)) &&
					<Grid container spacing={1} style={{"marginTop":"0px"}}>
						{!!(currency==="DHS") &&
							<Grid item xs={12} className="field padding-min">
								<label htmlFor="quantity">QTY*</label>
								<div className="p-inputgroup">
									<InputText name="quantity" id="quantity" style={{"width":"100%"}} pattern={(selectedItem.decimal_places===0?"[0-9]*":"[0-9.]*")} inputmode="numeric" value={inputValuesItems.quantity} onChange={(evt) => handleChangeFormatted(evt)}/>
									<span className="p-inputgroup-addon" style={{"backgroundColor":"lightgrey"}}>{selectedItem.abbreviation}</span>
								</div>
							</Grid>
						}
						{!!(currency!=="DHS") &&
							<React.Fragment>
								<Grid item xs={6} className="field padding-min">
									<label htmlFor="quantity">QTY*</label>
									<div className="p-inputgroup">
										<InputText name="quantity" id="quantity" style={{"width":"100%"}} pattern={(selectedItem.decimal_places===0?"[0-9]*":"[0-9.]*")} inputmode="numeric" value={inputValuesItems.quantity} onChange={(evt) => handleChangeFormatted(evt)}/>
										<span className="p-inputgroup-addon" style={{"backgroundColor":"lightgrey"}}>{selectedItem.abbreviation}</span>
									</div>
								</Grid>
								<Grid item xs={6} className="field padding-min">
									<label htmlFor="quantity">Exchange rate*</label>
									<div className="p-inputgroup">
										<InputText name="xe_rate" id="xe_rate" style={{"width":"100%"}} pattern={"[0-9.]*"} inputmode="numeric" value={xeRate} disabled={(((itemsList.length>0)||(lockPrice||props.session.lock_prices))?true:false)} onChange={(evt) => handleChangeFormatted(evt)}/>
										<span className="p-inputgroup-addon" style={{"backgroundColor":"lightgrey"}}>DHS</span>
									</div>
								</Grid>
							</React.Fragment>
						}
						{!!(props.session.max_discount===0) &&
							<React.Fragment>
								<Grid item xs={6} className="field padding-min">
									<label htmlFor="unit_price">Unit price*</label>
									<div className="p-inputgroup">
										<InputText name="unit_price" id="unit_price" style={{"width":"100%"}} pattern="[0-9.]*" inputmode="numeric" value={inputValuesItems.unit_price} disabled={((lockPrice||props.session.lock_prices)?true:false)} onChange={(evt) => handleChangeFormatted(evt)}/>
										<span className="p-inputgroup-addon" style={{"backgroundColor":"lightgrey"}}>{currency}</span>
									</div>
								</Grid>
								<Grid item xs={6} className="field padding-min">
									<label htmlFor="currency">Currency*</label>
									<Dropdown name="currency" id="currency" style={{"width":"100%"}} options={currencyList} value={currency} disabled={(((itemsList.length>0)||(lockPrice||props.session.lock_prices))?true:false)} onChange={(evt) => handleChangeFormatted(evt)}/>
								</Grid>						
							</React.Fragment>
						}
						{!!(props.session.max_discount>0) &&
							<React.Fragment>
								<Grid item xs={6} sm={4} md={4} lg={4} className="field padding-min">
									<label htmlFor="unit_price">Unit price*</label>
									<div className="p-inputgroup">
										<InputText name="unit_price" id="unit_price" style={{"width":"100%"}} pattern="[0-9.]*" inputmode="numeric" value={inputValuesItems.unit_price} disabled={((lockPrice||props.session.lock_prices)?true:false)} onChange={(evt) => handleChangeFormatted(evt)}/>
										<span className="p-inputgroup-addon" style={{"backgroundColor":"lightgrey"}}>{currency}</span>
									</div>
								</Grid>
								<Grid item xs={6} sm={4} md={4} lg={4} className="field padding-min">
									<label htmlFor="discount">Discount</label>
									<Dropdown name="discount" id="discount" style={{"width":"100%"}} options={discountsList} value={inputValuesItems.discount} onChange={(evt) => handleChangeFormatted(evt)}/>
								</Grid>						
								<Grid item xs={6} sm={4} md={4} lg={4} className="field padding-min">
									<label htmlFor="currency">Currency*</label>
									<Dropdown name="currency" id="currency" style={{"width":"100%"}} options={currencyList} value={currency} disabled={(((itemsList.length>0)||(lockPrice||props.session.lock_prices))?true:false)} onChange={(evt) => handleChangeFormatted(evt)}/>
								</Grid>						
							</React.Fragment>
						}
						<Grid item xs={12} className="padding-min align_center">
							<Button label="Add item to invoice" icon="pi pi-plus-circle" className="p-button-sm p-button-raised" style={{"marginTop":"9px","height":"38px"}} onClick={(evt) => handleButton(evt,"add_item")}/>
						</Grid>
					</Grid>
				}
			</Dialog>
		);
	}

	const barcodeDialog = () => {
		return(
			<Dialog header={dialogValues.titleBarcode} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openBarcode:false})} className="responsive-dialog-small">
				<Grid container spacing={1} style={{"paddingTop":"12px"}}>
					<Grid item xs={12} sm={12} md={12} lg={12} className="field padding-med">
						<label htmlFor="barcode">Barcode</label>
						<InputText name="barcode" id="barcode" style={{"width":"100%"}} value={inputValues.barcode} onChange={(evt) => handleChange(evt)} onKeyDown={(evt) => handleEnter(evt,"barcode")}/>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} className="field padding-med">
						<label htmlFor="barcode_item">Corresponding item for barcode</label>
						{inputValues.barcode==="" &&
							<InputTextarea rows={4} name="barcode_item" id="barcode_item" style={{"width":"99%"}} value={(barcodeItem?barcodeItem.description:"Please enter barcode...")} disabled={true}/>
						}
						{inputValues.barcode!=="" &&
							<InputTextarea rows={4} name="barcode_item" id="barcode_item" style={{"width":"99%"}} value={(barcodeItem?barcodeItem.description:"No item found.")} disabled={true}/>
						}					
					</Grid>						
					{!!(!barcodeItem) &&
						<Grid item xs={12} sm={12} md={12} lg={12} style={{"textAlign":"right","marginTop":"12px"}}>
							<Button label="Search" icon="pi pi-search" className="p-button-sm p-button-success p-button-raised" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"search_barcode",1)}/>
							<Button label="Close" icon="pi pi-times" className="p-button-sm p-button-danger p-button-raised" onClick={(evt) => setDialogValues({...dialogValues,openBarcode:false})}/>
						</Grid>
					}
					{!!(barcodeItem) &&
						<React.Fragment>
							<Grid item xs={12} sm={12} md={12} lg={12} style={{"textAlign":"right","marginTop":"12px"}}>
								<Button label="Add to invoice" icon="pi pi-plus" className="p-button-sm p-button-success p-button-raised" style={{"marginRight":"3px"}} onClick={(evt) => handleButton(evt,"select_item_from_browse",barcodeItem)}/>
								<Button label="Clear fields" icon="pi pi-trash" className="p-button-sm p-button-danger p-button-raised" onClick={(evt) => handleButton(evt,"enter_barcode",barcodeItem)}/>
							</Grid>
						</React.Fragment>
					}
				</Grid>
			</Dialog>
		);			
	}

	const insufficientStockDialog = () => {
		return(
			<Dialog header={dialogValues.titleInsufficientStock} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openInsufficientStock:false})} className="responsive-dialog-small-med">
				<div className="" style={{}}>
					<DataTable
						value={neededQuantities}
						scrollable
						style={{"marginTop":"9px"}}
						scrollDirection="both"
						stripedRows
						resizableColumns
						columnResizeMode="fit"
						responsiveLayout="stack"
					>
						<Column field="description" header="Item description" style={{"flexGrow":"1","flexBasis":"120px"}} sortable></Column>
						<Column field="requested_quantity" header="Requested" style={{"flexGrow":"0","flexBasis":"100px","paddingLeft":"1rem"}}></Column>
						<Column field="existing_quantity" header="Existing" style={{"flexGrow":"0","flexBasis":"100px"}}></Column>
					</DataTable>
				</div>
			</Dialog>
		);			
	}

	const confirm = (evt,what,elementData) => {
		if(what==="delete_item") {
				confirmDialog({
						message: "Are you sure you want to delete?",
						header: "Confirm delete",
						icon: "pi pi-exclamation-triangle",
						accept: () => handleButton(evt,what,elementData),
						reject: () => null,
				});
		}
		if(what==="reset") {
			confirmDialog({
					message: "Are you sure you want to delete all data of current invoice?",
					header: "Confirm delete data",
					icon: "pi pi-exclamation-triangle",
					accept: () => handleButton(evt,what,elementData),
					reject: () => null,
			});
		}
		if(what==="new") {
			if(wasSent) {
				handleButton(evt,what,elementData);
			}else{
				confirmDialog({
					message: "Are you sure you want to start new invoice? Current invoice data will be deleted.",
					header: "Confirm new invoice",
					icon: "pi pi-exclamation-triangle",
					accept: () => handleButton(evt,what,elementData),
					reject: () => null,
			});
			}
		}
	}

	if(dataOk) {
		return(
			<BlockUI blocked={blockScreen} fullScreen>
				<Box sx={{ display: { xs: "block", sm: "block", md: "none", lg: "none", xl: "none" } }}>
					{!!(invoiceState==="open") &&
						<div style={{}}>
							<div className="invoice_up_div">
								<Grid container spacing={1} style={{"marginTop":"12px","marginLeft":"0px"}}>
									<Grid item xs={12} sm={12} md={7} className="field padding-min">
										<AutoComplete value={selectedClient} suggestions={filteredClientsList} completeMethod={searchClients} field="name" style={{"width":"97%","marginLeft":"0px"}} dropdown forceSelection showEmptyMessage minLength={3} placeholder="Please select a client" onChange={(evt) => setSelectedClient(evt.value)} disabled={walkin}/>
									</Grid>
									<Grid item xs={6} sm={6} md={2} className="mfa_invoice_client align_center">
										<div className="field-checkbox">
											<Checkbox inputId="walkin" checked={walkin} onChange={(evt) => toggleWalkIn(evt)} disabled={(invoiceState==="open"?false:true)}/>
											<label htmlFor="walkin">Walk-in client</label>
										</div>
									</Grid>
									<Grid item xs={6} sm={6} md={3} className="field padding-med align_right">
										<Button label="New" icon="pi pi-plus-circle" className="p-button-sm p-button-raised" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"add_client")} disabled={walkin}/>
										<Button label="Edit" icon="pi pi-pencil" className="p-button-sm p-button-raised" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"edit_client",selectedClient)} disabled={walkin}/>
									</Grid>
									{!!(selectedClient) &&
										<React.Fragment>
											<Grid item xs={12} sm={12} md={12} className="field padding-min" style={{}}>
												<Dropdown name="item" id="item" style={{"width":"97%"}} value={selectedItem} onChange={(evt) => handleItemChange(evt)} disabled={(invoiceState==="open"?false:true)} options={dataValues.customerItemsList} optionLabel="sku_description" placeholder="Please select an item" filter/>
											</Grid>
											<Grid item xs={12} sm={12} md={12} className="field" style={{"padding":"15px 3px 3px 3px"}}>
												<Button id="browse_inventory" label="OR you can browse products and services" className="p-button-sm p-button-raised" style={{"marginTop":"-6px","width":"96%","marginBottom":"12px"}} onClick={(evt) => handleButton(evt,"browse_inventory")}/>
											</Grid>
										</React.Fragment>
									}
								</Grid>
								{!!(selectedClient) &&								
									<React.Fragment>
										<Divider layout="horizontal" align="center">
											<span className="p-tag">Items list</span>
										</Divider>
										<ScrollPanel style={{"height":"54%"}}>
											<Grid container spacing={1} style={{"marginTop":"0px","maxHeight":"99vh","marginLeft":"0px"}}>
												{itemsList.length>0 && itemsList.map((item,index)=>
													<Grid item xs={12} sm={12} md={12} key={index} className="field padding-min align_right">
														<Card title={item.description} className="items_list_mobile" onClick={(evt) => handleButton(evt,"browse_groups")}>
															<span className="items_list_mobile_details">
																<div style={{"width":"48%","float":"left","textAlign":"left"}}>QTY: {item.quantity} {item.abbr}</div>
																<div style={{"width":"51%","float":"right","textAlign":"left"}}>Price: {item.unit_price} {item.currency}</div>
															</span>
															<Button label="Remove item" icon="pi pi-trash" className="p-button-sm p-button-raised p-button-danger" style={{"marginTop":"0px","height":"40px"}} onClick={(evt) => confirm(evt,"delete_item",index)} disabled={(invoiceState==="open"?false:true)}/>
														</Card>					
													</Grid>
												)}
											</Grid>
										</ScrollPanel>
										<Divider layout="horizontal" align="center">
											<span className="p-tag">Invoice notes</span>
										</Divider>
										<div className="field" style={{"textAlign":"center"}}>
											<InputTextarea rows={4} name="notes" id="notes" style={{"width":"99%"}} value={inputValues.notes} onChange={(evt) => handleChange(evt)} disabled={(invoiceState==="open"?false:true)}/>
										</div>
										<Grid container spacing={1} style={{"marginTop":"0px","marginLeft":"3px","marginBottom":"24px"}}>								
											{!!(!dataValues.customerDetails.vat_registered) &&
												<React.Fragment>
													<Grid item xs={6} sm={6} md={3} className="field padding-min">
														<label htmlFor="subtotal">Subtotal</label>
														<div className="p-inputgroup">
															<InputText name="subtotal" id="subtotal" style={{"width":"90%"}} value={((totals.subtotal*1000)/1000).toFixed(2)}/>
															<span className="p-inputgroup-addon" style={{"backgroundColor":"lightgrey"}}>{currency}</span>
														</div>
													</Grid>
													<Grid item xs={6} sm={6} md={3} className="field padding-min">
														<label htmlFor="total">Total</label>
														<div className="p-inputgroup">
															<InputText name="total" id="total" style={{"width":"88%"}} value={((totals.total*1000)/1000).toFixed(2)}/>
															<span className="p-inputgroup-addon" style={{"backgroundColor":"lightgrey"}}>{currency}</span>
														</div>
													</Grid>
												</React.Fragment>
											}
											{!!(dataValues.customerDetails.vat_registered) &&
												<React.Fragment>
													<Grid item xs={4} sm={4} md={2} className="field padding-min">
														<label htmlFor="subtotal">Subtotal</label>
														<div className="p-inputgroup">
															<InputText name="subtotal" id="subtotal" style={{"width":"90%"}} value={((totals.subtotal*1000)/1000).toFixed(2)}/>
															<span className="p-inputgroup-addon" style={{"backgroundColor":"lightgrey"}}>{currency}</span>
														</div>
													</Grid>
													<Grid item xs={4} sm={4} md={2} className="field padding-min">
														<label htmlFor="vat">VAT</label>
														<div className="p-inputgroup">
															<InputText name="vat" id="vat" style={{"width":"90%"}} value={((totals.vat*1000)/1000).toFixed(2)}/>
															<span className="p-inputgroup-addon" style={{"backgroundColor":"lightgrey"}}>{currency}</span>
														</div>
													</Grid>
													<Grid item xs={4} sm={4} md={2} className="field padding-min">
														<label htmlFor="total">Total</label>
														<div className="p-inputgroup">
															<InputText name="total" id="total" style={{"width":"88%"}} value={((totals.total*1000)/1000).toFixed(2)}/>
															<span className="p-inputgroup-addon" style={{"backgroundColor":"lightgrey"}}>{currency}</span>
														</div>
													</Grid>
												</React.Fragment>
											}
											{!!((selectedClient)&&(itemsList.length>0)) &&
												<React.Fragment>
													<Grid item xs={6} sm={6} md={4} className="align_center">
														<Button label="Reset invoice" icon="pi pi-times" className="p-button-small p-button-success p-button-raised" style={{"marginTop":"18px"}} onClick={(evt) => confirm(evt,"reset")}/>                                              
													</Grid>
													<Grid item xs={6} sm={6} md={2} className="align_center">
														<Button label="Save invoice" icon="pi pi-check-circle" className="p-button-small p-button-raised" style={{"marginTop":"18px","marginLeft":"-42px"}} onClick={(evt) => handleButton(evt,"save")}/>
													</Grid>
												</React.Fragment>
											}
										</Grid>
									</React.Fragment>
								}
							</div>
							{!!(!selectedClient) &&
								<div className="mfa_invoice_no_client">
									<p>Please select a client.</p>
								</div>
							}
						</div>
					}
					{!!((invoiceState==="close")&&(selectedClient)) &&
						<Grid container spacing={1} style={{"marginTop":"15px"}}>
							<React.Fragment>
								<Grid item xs={12}>
									<Button label="Create new invoice" icon="pi pi-refresh" tooltip="New invoice" className="p-button-small p-button-success invoice_operations_button p-button-raised" style={{}} onClick={(evt) => confirm(evt,"new")}/>                                              
								</Grid>
								{!!((props.session.mobile_printer==="sunmi")||(props.session.mobile_printer==="BLP")) &&
									<Grid item xs={12}>
										<Button label="Print current invoice" icon="pi pi-print" tooltip="Print invoice" className="p-button-small invoice_operations_button p-button-raised" style={{}} onClick={(evt) => handleButton(evt,"print")}/> 
									</Grid>
								}
								{!!(!wasSent) &&
									<Grid item xs={12}>
										<Button label="Back to invoice edit" icon="pi pi-pencil" tooltip="Edit invoice" className="p-button-small invoice_operations_button p-button-raised" style={{}} onClick={(evt) => handleButton(evt,"edit")}/>
									</Grid>
								}
								<Grid item xs={12}>
									<Button label="Send invoice to WhatsApp" icon="pi pi-whatsapp" tooltip="Send to WhatsApp" className="p-button-small invoice_operations_button p-button-raised" style={{}} onClick={(evt) => handleButton(evt,"whatsapp",0)}/>                                                  
								</Grid>	
								<Grid item xs={12}>
									<Button label="Send invoice to email" icon="pi pi-envelope" tooltip="Send to WhatsApp" className="p-button-small invoice_operations_button p-button-raised" style={{}} onClick={(evt) => handleButton(evt,"email",0)}/>                                                  
								</Grid>	
							</React.Fragment>
						</Grid>
					}
					{!!((invoiceState==="close")&&(!selectedClient)) &&
						<div className="mfa_invoice_no_client">
							<p>Please select a client.</p>
						</div>
					}
				</Box>
				<Box sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block", xl: "block" } }}>
					<div style={{}}>
						<div className="invoice_left_div">
							<Grid container spacing={1} style={{"marginTop":"12px"}}>
								<Grid item xs={3} className="mfa_invoice_client">
									<div className="field-checkbox">
										<Checkbox inputId="walkin" checked={walkin} onChange={(evt) => toggleWalkIn(evt)} disabled={(invoiceState==="open"?false:true)}/>
										<label htmlFor="walkin">Walk-in client</label>
									</div>
								</Grid>
								<Grid item xs={6} className="field padding-med">
									<AutoComplete value={selectedClient} suggestions={filteredClientsList} completeMethod={searchClients} field="name" style={{"width":"103%"}} dropdown forceSelection showEmptyMessage minLength={3} onChange={(evt) => setSelectedClient(evt.value)} disabled={walkin}/>
								</Grid>
								<Grid item xs={3} className="field" style={{"marginTop":"1px","paddingLeft":"0px"}}>
									<Button label="New" icon="pi pi-plus-circle" className="p-button-sm p-button-raised" style={{"marginTop":"0px","marginRight":"12px","marginLeft":"15px"}} onClick={(evt) => handleButton(evt,"add_client")} disabled={walkin}/>
									<Button label="Edit" icon="pi pi-pencil" className="p-button-sm p-button-raised" style={{"marginTop":"0px","width":"68px"}} onClick={(evt) => handleButton(evt,"edit_client",selectedClient)} disabled={walkin}/>
								</Grid>
								{!!(selectedClient) &&
									<React.Fragment>
										<Grid item xs={9} className="field" style={{"paddingTop":"3px","paddingBottom":"18px","paddingLeft":"21px"}}>
											<label htmlFor="item">Item*</label>
											<Dropdown name="item" id="item" style={{"width":"100%"}} value={selectedItem} onChange={(evt) => handleItemChange(evt)} disabled={(invoiceState==="open"?false:true)} options={dataValues.customerItemsList} optionLabel="sku_description" placeholder="Please select an item" filter/>
										</Grid>
										<Grid item xs={3} style={{"paddingLeft":"1px"}}>
											<Button label="Browse" className="p-button-sm p-button-raised" style={{"marginTop":"18px","marginBottom":"12px","marginRight":"3px","float":"left","display":"none"}} onClick={(evt) => handleButton(evt,"browse_inventory")} disabled={(invoiceState==="open"?false:true)}/>
											<Button label="Enter barcode" className="p-button-sm p-button-raised" style={{"marginTop":"18px","marginBottom":"12px","float":"left","marginLeft":"12px","width":"84%"}} onClick={(evt) => handleButton(evt,"enter_barcode")} disabled={(invoiceState==="open"?false:true)}/>
										</Grid>
									</React.Fragment>
								}
							</Grid>
							<Divider layout="horizontal" align="center">
								<span className="p-tag">Invoice notes</span>
							</Divider>
							<div className="field" style={{"textAlign":"center"}}>
								<InputTextarea rows={4} name="notes" id="notes" style={{"width":"96%"}} value={inputValues.notes} onChange={(evt) => handleChange(evt)} disabled={(invoiceState==="open"?false:true)}/>
							</div>
							<Divider layout="horizontal" align="center">
								<span className="p-tag">Invoice totals</span>
							</Divider>
							{!!(props.session.invoice_numbering==="AUTOMATIC") &&
								<Grid container spacing={1} style={{"marginTop":"0px","marginLeft":"6px","marginBottom":"24px","width":"97%"}}>								
									{!!(!dataValues.customerDetails.vat_registered) &&
										<React.Fragment>
											<Grid item xs={6} className="field padding-min">
												<label htmlFor="subtotal">Subtotal</label>
												<div className="p-inputgroup">
													<InputText name="subtotal" id="subtotal" style={{}} value={((totals.subtotal*1000)/1000).toFixed(2)}/>
													<span className="p-inputgroup-addon" style={{"backgroundColor":"lightgrey"}}>{currency}</span>
												</div>
											</Grid>
											<Grid item xs={6} className="field padding-min">
												<label htmlFor="total">Total</label>
												<div className="p-inputgroup">
													<InputText name="total" id="total" style={{"width":"88%"}} value={((totals.total*1000)/1000).toFixed(2)}/>
													<span className="p-inputgroup-addon" style={{"backgroundColor":"lightgrey"}}>{currency}</span>
												</div>
											</Grid>
										</React.Fragment>
									}
									{!!(dataValues.customerDetails.vat_registered) &&
										<React.Fragment>
											<Grid item xs={4} className="field padding-min">
												<label htmlFor="subtotal">Subtotal</label>
												<div className="p-inputgroup">
													<InputText name="subtotal" id="subtotal" style={{}} value={((totals.subtotal*1000)/1000).toFixed(2)}/>
													<span className="p-inputgroup-addon" style={{"backgroundColor":"lightgrey"}}>{currency}</span>
												</div>
											</Grid>
											<Grid item xs={4} className="field padding-min">
												<label htmlFor="vat">VAT</label>
												<div className="p-inputgroup">
													<InputText name="vat" id="vat" style={{}} value={((totals.vat*1000)/1000).toFixed(2)}/>
													<span className="p-inputgroup-addon" style={{"backgroundColor":"lightgrey"}}>{currency}</span>
												</div>
											</Grid>
											<Grid item xs={4} className="field padding-min">
												<label htmlFor="total">Total</label>
												<div className="p-inputgroup">
													<InputText name="total" id="total" style={{}} value={((totals.total*1000)/1000).toFixed(2)}/>
													<span className="p-inputgroup-addon" style={{"backgroundColor":"lightgrey"}}>{currency}</span>
												</div>
											</Grid>
										</React.Fragment>
									}
									{!!((invoiceState==="open")&&(itemsList.length>0)) &&
										<React.Fragment>
											<Grid item xs={12} className="align_right field" style={{"paddingTop":"27px","paddingRight":"6px"}}>
												<Button label="Reset data" icon="pi pi-times" className="p-button-sm p-button-success p-button-raised" style={{"marginRight":"6px"}} onClick={(evt) => confirm(evt,"reset")}/>                                              
												<Button label="Save invoice" icon="pi pi-check-circle" className="p-button-sm p-button-raised" style={{}} onClick={(evt) => handleButton(evt,"save")}/>
											</Grid>
										</React.Fragment>
									}
								</Grid>
							}
							{!!(props.session.invoice_numbering!=="AUTOMATIC") &&
								<Grid container spacing={1} style={{"marginTop":"0px","marginLeft":"6px","marginBottom":"24px","width":"97%"}}>								
									{!!(!dataValues.customerDetails.vat_registered) &&
										<React.Fragment>
											<Grid item xs={6} className="field padding-min">
												<label htmlFor="subtotal">Subtotal</label>
												<div className="p-inputgroup">
													<InputText name="subtotal" id="subtotal" style={{}} value={((totals.subtotal*1000)/1000).toFixed(2)}/>
													<span className="p-inputgroup-addon" style={{"backgroundColor":"lightgrey"}}>{currency}</span>
												</div>
											</Grid>
											<Grid item xs={6} className="field padding-min">
												<label htmlFor="total">Total</label>
												<div className="p-inputgroup">
													<InputText name="total" id="total" style={{"width":"88%"}} value={((totals.total*1000)/1000).toFixed(2)}/>
													<span className="p-inputgroup-addon" style={{"backgroundColor":"lightgrey"}}>{currency}</span>
												</div>
											</Grid>
										</React.Fragment>
									}
									{!!(dataValues.customerDetails.vat_registered) &&
										<React.Fragment>
											<Grid item xs={4} className="field padding-min">
												<label htmlFor="subtotal">Subtotal</label>
												<div className="p-inputgroup">
													<InputText name="subtotal" id="subtotal" style={{}} value={((totals.subtotal*1000)/1000).toFixed(2)}/>
													<span className="p-inputgroup-addon" style={{"backgroundColor":"lightgrey"}}>{currency}</span>
												</div>
											</Grid>
											<Grid item xs={4} className="field padding-min">
												<label htmlFor="vat">VAT</label>
												<div className="p-inputgroup">
													<InputText name="vat" id="vat" style={{}} value={((totals.vat*1000)/1000).toFixed(2)}/>
													<span className="p-inputgroup-addon" style={{"backgroundColor":"lightgrey"}}>{currency}</span>
												</div>
											</Grid>
											<Grid item xs={4} className="field padding-min">
												<label htmlFor="total">Total</label>
												<div className="p-inputgroup">
													<InputText name="total" id="total" style={{}} value={((totals.total*1000)/1000).toFixed(2)}/>
													<span className="p-inputgroup-addon" style={{"backgroundColor":"lightgrey"}}>{currency}</span>
												</div>
											</Grid>
										</React.Fragment>
									}
									{!!((invoiceState==="open")&&(itemsList.length>0)) &&
										<React.Fragment>
											<Grid item xs={4} className="align_right field" style={{"paddingTop":"18px","paddingRight":"6px"}}>
												<label htmlFor="number">Invoice no.</label>
												<InputText name="number" id="number" style={{}} value={inputValues.number} onChange={(evt) => handleChange(evt)}/>
											</Grid>
											<Grid item xs={8} className="align_right field" style={{"paddingTop":"18px","paddingRight":"6px"}}>
												<Button label="Reset data" icon="pi pi-times" className="p-button-sm p-button-success p-button-raised" style={{"marginRight":"6px"}} onClick={(evt) => confirm(evt,"reset")}/>                                              
												<Button label="Save invoice" icon="pi pi-check-circle" className="p-button-sm p-button-raised" style={{}} onClick={(evt) => handleButton(evt,"save")}/>
											</Grid>
										</React.Fragment>
									}
								</Grid>
							}
						</div>
						<div className="invoice_right_div">
							<Grid container spacing={1} style={{"paddingTop":"21px","paddingLeft":"12px","paddingRight":"15px","paddingBottom":"0px"}}>
								{!!(invoiceState==="close") &&
									<React.Fragment>
										<Grid item xs={3} className="align_left field">
											<Button label="New invoice" icon="pi pi-refresh" className="p-button-sm p-button-success p-button-raised" style={{}} onClick={(evt) => confirm(evt,"new")}/>                                              
										</Grid>
										<Grid item xs={9} className="align_center">
											{!!(wasSent) && 
												<div style={{"width":"24%","float":"left"}}>
													&nbsp;
												</div>
											}
											{!!(!wasSent) && 
												<div style={{"width":"27%","float":"left"}}>
													<Button label="Edit invoice" icon="pi pi-pencil" className="p-button-sm p-button-raised" style={{}} onClick={(evt) => handleButton(evt,"edit")}/>
												</div>
											}
											<div style={{"width":"25%","float":"left"}}>
												<Button label="Export PDF" icon="pi pi-file-pdf" className="p-button-sm p-button-raised" style={{}} onClick={(evt) => handleButton(evt,"pdf")}/>                                              
											</div>
											<div style={{"width":"23%","float":"left"}}>
												<Button label="Sendmail" icon="pi pi-envelope" className="p-button-sm p-button-raised" style={{}} onClick={(evt) => handleButton(evt,"email")}/>                                                  
											</div>
											<div style={{"width":"23%","float":"left"}}>
												<Button label="Whatsapp" icon="pi pi-whatsapp" className="p-button-sm p-button-raised" style={{}} onClick={(evt) => handleButton(evt,"whatsapp")}/>          
											</div>                                    
										</Grid>
									</React.Fragment>
								}
							</Grid>
							{!!(selectedClient) &&
								<React.Fragment>
									<h5 className="align_center" style={{"color":"#000000","marginTop":"9px","marginBottom":"3px"}}>Invoice preview ({currency})</h5>
									<Divider layout="horizontal" align="center">
										<span className="p-tag">Items list</span>
									</Divider>
									<ScrollPanel style={{"width":"99%","height":"64vh"}}>
										<Grid container spacing={1} style={{"marginTop":"-18px"}}>
											{itemsList.length>0 && itemsList.map((item,index)=>
												<React.Fragment key={index}>
													<Grid item xs={11} className="field" style={{"paddingTop":"3px","paddingBottom":"3px","marginTop":"18px"}}>
														<InputText style={{"width":"100%"}} value={item.description}/>
													</Grid>
													<Grid item xs={1} className="field">
														<Button icon="pi pi-trash" className="p-button-sm p-button-raised p-button-danger" style={{"marginLeft":"3px","marginTop":"13px","width":"90%"}} onClick={(evt) => confirm(evt,"delete_item",index)} disabled={(invoiceState==="open"?false:true)}/>
													</Grid>
													<Grid item xs={2} className="field">
														<label htmlFor="">List price</label>
														<InputText tooltip={item.list_price+" "+item.currency} style={{"width":"100%"}} value={item.list_price}/>
													</Grid>
													<Grid item xs={2} className="field">
														<label htmlFor="">Discount(%)</label>
														<InputText tooltip={item.discount*100+"%"} style={{"width":"100%"}} value={item.discount*100}/>
													</Grid>
													<Grid item xs={2} className="field">
														<label htmlFor="">Final price</label>
														<InputText tooltip={item.unit_price.toFixed(2)+" "+item.currency} style={{"width":"100%"}} value={item.unit_price.toFixed(2)}/>
													</Grid>
													{!!(dataValues.customerDetails.vat_registered) &&
														<Grid item xs={2} className="field">
															<label htmlFor="">VAT</label>
															<InputText tooltip={((item.vat*100)/(item.quantity*100)).toFixed(2)+" "+item.currency} style={{"width":"100%"}} value={((item.vat*100)/(item.quantity*100)).toFixed(2)}/>
														</Grid>
													}
													<Grid item xs={2} className="field">
														<label htmlFor="">Quantity</label>
														<InputText tooltip={item.quantity+" "+item.abbr} style={{"width":"100%"}} value={item.quantity}/>
													</Grid>
													<Grid item xs={2} className="field">
														<label htmlFor="">Line total</label>
														<InputText tooltip={item.line_total+" "+item.currency} style={{"width":"100%"}} value={item.line_total}/>
													</Grid>
												</React.Fragment>									
											)}
										</Grid>
									</ScrollPanel>
								</React.Fragment>
							}
							{!!(!selectedClient) &&
								<div className="mfa_invoice_no_client">
									<p>Please select a client.</p>
								</div>
							}
						</div>
					</div>
				</Box>
				<ConfirmDialog />
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
				{dialogValues.openAddEdit && addEditDialog()}
				{dialogValues.openAddPhone && addPhoneDialog()}
				{dialogValues.openAddEmail && addEmailDialog()}
				{dialogValues.openBrowseInventory && browseInventoryDialog()}
				{dialogValues.openPriceAndQuantity && addPriceAndQuantityDialog()}
				{dialogValues.openBarcode && barcodeDialog()}
				{dialogValues.openInsufficientStock && insufficientStockDialog()}
			</BlockUI>
		);
	}else{
		return(
			<React.Fragment>
				<LinearProgress />
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
			</React.Fragment>
		);
	}
};

export default Invoice;
