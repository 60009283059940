import React from "react";
import {useState,useEffect} from "react";
import {useLocation,useNavigate} from "react-router-dom";
import {Button} from "primereact/button";
import {FileUpload} from "primereact/fileupload";
import {BlockUI} from "primereact/blockui";
import {Tag} from "primereact/tag";
import {Divider} from "primereact/divider";
import {ScrollPanel} from "primereact/scrollpanel";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import Message from "../Helpers/Message.js";
import {fetch_url_post,fetch_download} from "../../Sources/js/app.js";
import {get_api_path} from "../../Sources/js/paths.js";

let oper="";

let defaultDataValues={
	filesList: [],
};

const Expenses = (props) => {

	var timerId=null;
	const [dataOk, setDataOk] = useState(true);
	const [blockScreen, setBlockScreen] = useState(false);
	const [dataValues, setDataValues] = useState(defaultDataValues);
	const [messageText, setMessageText] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();

	const handleButton = (evt,what,elementData) => {
		if(what==="download") {
			let url="downloadExpensesFile";
			url+="?session_id="+props.session.sessId;
			let data={
				id: elementData.id,
			};
			fetch_download(url,data,elementData.filename,success_handler);
		}
	}

	const success_handler = (result) => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
			setMessageText("");
		}
		if(result.ok===0) {
			setMessageText("Error: "+result.error);
			return false;
		}
		if(result.is_auth===0) {
			navigate("/login");			
			return false;
		}
		if(result.message) {
			setMessageText(result.message);			
		}
		if(oper==="init") {
			setDataValues({...dataValues,filesList:(result.filesList?result.filesList:[])});
            setDataOk(true);
			oper="";
		}
		if(oper==="get_expenses_files") {
			setDataValues({...dataValues,filesList:(result.filesList?result.filesList:[])});
			oper="";
		}
	}

	const fail_handler = (error) => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
		}
		console.log(error);
		setMessageText("Connection error please try again later!");
	}

	const wait_handler = () => {
		setBlockScreen(true);
		setMessageText("Operation is in progress. Please wait...");
	}

	useEffect(() => {
		if(props.session.sessId) {
			oper="init";
			let url="getExpensesFiles";
			url+="?session_id="+props.session.sessId;
			let data={};
			fetch_url_post(url,data,success_handler,fail_handler);
		}else{
			navigate("/login");
		}
	},[]);

    const onUpload = () => {
        setMessageText("File was succesfully uploaded!");
        oper="get_expenses_files";
        let url="getExpensesFiles";
        url+="?session_id="+props.session.sessId;
		let data={};
        timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
    }

    const onBeforeUpload = (evt) => {
//        evt.formData.append("doc_id",props.document.id);
    }

    const chooseOptions = {label: "Add file", className: "p-button-sm p-button-primary p-button-raised"};
    const uploadOptions = {label: "Upload files", className: "p-button-sm p-button-primary p-button-raised"};
    const cancelOptions = {label: "Clear all", className: "p-button-sm p-button-danger p-button-raised"};

    const uploadItemTemplate = (file, props) => {
        return (
            <div style={{"width":"100%","marginBottom":"3px","marginTop":"21px"}}>
				<div style={{"width":"90%","float":"left"}}>
                	<Tag value={file.name} severity="warning" style={{"width":"100%","textAlign":"left","marginTop":"0px","height":"37px"}} />
				</div>
				<div style={{"width":"9%","float":"right","textAlign":"center"}}>
                	<Button type="button" icon="pi pi-times" className="p-button-sm p-button-danger p-button-raised" onClick={() => onTemplateRemove(file, props.onRemove)} />
				</div>
            </div>
        )
    }

    const onTemplateRemove = (file, callback) => {
        callback();
    }

	if(dataOk) {
		let base_url=get_api_path();
		let url=base_url+"uploadExpensesFile?session_id="+props.session.sessId;
		return(
			<BlockUI blocked={blockScreen} fullScreen>
				<div className="responsive-dialog-big" style={{"margin":"0 auto"}}>
					<FileUpload name="new_file"
								url={url}
								onUpload={onUpload}
								onBeforeUpload={onBeforeUpload}
								multiple
								accept="image/*|.pdf"
								maxFileSize={10000000}
								emptyTemplate={<p style={{"width":"100%","textAlign":"center","margin":"0px"}}>Drag file(s) here or press 'Search file' button.</p>}
								chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} itemTemplate={uploadItemTemplate}
					/>
					<Divider layout="horizontal" align="center">
						<span className="p-tag">Expenses in the last 30 days</span>
					</Divider>
						<ScrollPanel style={{"height":"54%"}}>
							<Grid container spacing={1} style={{"marginTop":"0px","maxHeight":"99vh","marginLeft":"0px"}}>
								{dataValues.filesList.length>0 && dataValues.filesList.map((file,index)=>
									<React.Fragment key={index}>
										<Grid item xs={12} sm={12} md={6} lg={6} xl={6} className="field padding-min expense" style={{"cursor":"pointer"}} onClick={(evt) => handleButton(evt,"download",file)}>
											<Tag value={file.filename} className="expense" style={{"width":"100%","textAlign":"left","marginTop":"0px","height":"37px"}} />										
										</Grid>
									</React.Fragment>									
								)}
							</Grid>
						</ScrollPanel>
				</div>
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
			</BlockUI>
		);
	}else{
		return(
			<React.Fragment>
				<LinearProgress />
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
			</React.Fragment>
		);
	}
};

export default Expenses;
