import React from "react";
import {useState,useEffect} from "react";
import {useLocation,useNavigate} from "react-router-dom";
import {BlockUI} from "primereact/blockui";
import Grid from "@mui/material/Grid";
import {Button} from "primereact/button";
import {Card} from "primereact/card";
import LinearProgress from "@mui/material/LinearProgress";
import Message from "../Helpers/Message.js";
import {fetch_url_post} from "../../Sources/js/app.js";

let oper="";

let defaultDataValues={
	customersList: [],
};

const Dashboard = (props) => {

	var timerId=null;
	const [dataOk, setDataOk] = useState(true);
	const [blockScreen, setBlockScreen] = useState(false);
	const [messageText, setMessageText] = useState(null);
	const [dataValues, setDataValues] = useState(defaultDataValues);
    const location = useLocation();
    const navigate = useNavigate();

	const handleButton = (evt,what) => {
		if(what==="invoice") {
			navigate("/invoice");
		}
		if(what==="expenses") {
			navigate("/expenses");
		}
		if(what==="pl") {
			navigate("/pl");
		}
		if(what==="inventory") {
			navigate("/inventory");
		}
		if(what==="invoices") {
			navigate("/invoices");
		}
		if(what==="clients") {
			navigate("/clients");
		}
	}

	const success_handler = (result) => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
			setMessageText("");
		}
		if(result.ok===0) {
			setMessageText("Error: "+result.error);
			return false;
		}
		if(result.is_auth===0) {
			navigate("/login");			
			return false;
		}
		if(result.message) {
			setMessageText(result.message);			
		}
	}

	const fail_handler = () => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
		}
		setMessageText("Connection error please try again later!");
	}

	const wait_handler = () => {
		setBlockScreen(true);
		setMessageText("Operation is in progress. Please wait...");
	}

	useEffect(() => {
		if(props.session.sessId) {
			oper="get";
			let url="getCustomers";
			url+="?session_id="+props.session.sessId;
			let data={
				short: 1,
			};
//			fetch_url_post(url,data,success_handler,fail_handler);
		}else{
			navigate("/login");
		}
	},[]);

    const cardHeader = (section) => {
		return(
			<div style={{"paddingTop":"12px"}}>
				<div style={{"width":"90%","float":"left","textAlign":"left","paddingLeft":"18px"}}>
					<h5 className="m-0" style={{"width":"70%","float":"left","fontSize":"1.25rem"}}>{section}</h5>
				</div>
			</div>
		)
    };

    const cardFooter = (section) => {
		return(
			<div style={{}}>
				<div style={{"float":"right","textAlign":"right","paddingRight":"12px","marginTop":"-30px"}}>
					<i className="pi pi-arrow-circle-right" style={{"fontSize":"1.5em"}}></i>
				</div>
			</div>
		)
    };

	if(dataOk) {
		return(
			<BlockUI blocked={blockScreen} fullScreen>
				<Grid container spacing={1} style={{}}>
					<Grid item xs={12} sm={12} md={3} lg={3} xl={3} className="field padding-med">
						<Card header={cardHeader("Invoice")} footer={cardFooter()} style={{"cursor":"pointer"}} onClick={(evt) => {handleButton(evt,"invoice")}}>
							<div style={{"minHeight":"180px","textAlign":"center","paddingTop":"10%","color":"grey"}}>
								<i className="pi pi-cart-plus mr-2" style={{"fontSize":"84px"}}></i>
							</div>
						</Card>
					</Grid>
					{!!(props.session.list_invoices) &&
						<Grid item xs={12} sm={12} md={3} lg={3} xl={3} className="field padding-med">
							<Card header={cardHeader("List of invoices")} footer={cardFooter()} style={{"cursor":"pointer"}} onClick={(evt) => {handleButton(evt,"invoices")}}>
								<div style={{"minHeight":"180px","textAlign":"center","paddingTop":"10%","color":"grey"}}>
									<i className="pi pi-calculator mr-2" style={{"fontSize":"84px"}}></i>
								</div>
							</Card>
						</Grid>
					}
					{!!(props.session.list_clients) &&
						<Grid item xs={12} sm={12} md={3} lg={3} xl={3} className="field padding-med">
							<Card header={cardHeader("List of clients")} footer={cardFooter()} style={{"cursor":"pointer"}} onClick={(evt) => {handleButton(evt,"clients")}}>
								<div style={{"minHeight":"180px","textAlign":"center","paddingTop":"10%","color":"grey"}}>
									<i className="pi pi-users mr-2" style={{"fontSize":"84px"}}></i>
								</div>
							</Card>
						</Grid>
					}
					<Grid item xs={12} sm={12} md={3} lg={3} xl={3} className="field padding-med">
						<Card header={cardHeader("Expenses")} footer={cardFooter()} style={{"cursor":"pointer"}} onClick={(evt) => {handleButton(evt,"expenses")}}>
							<div style={{"minHeight":"180px","textAlign":"center","paddingTop":"10%","color":"grey"}}>
								<i className="pi pi-wallet mr-2" style={{"fontSize":"84px"}}></i>
							</div>	
						</Card>
					</Grid>
					<Grid item xs={12} sm={12} md={3} lg={3} xl={3} className="field padding-med">
						<Card header={cardHeader("Profit & Loss")} footer={cardFooter()} style={{"cursor":"pointer"}} onClick={(evt) => {handleButton(evt,"pl")}}>
							<div style={{"minHeight":"180px","textAlign":"center","paddingTop":"10%","color":"grey"}}>
								<i className="pi pi-chart-line mr-2" style={{"fontSize":"84px"}}></i>
							</div>	
						</Card>
					</Grid>
					{!!(props.session.inventory) &&
						<Grid item xs={12} sm={12} md={3} lg={3} xl={3} className="field padding-med">
							<Card header={cardHeader("Inventory")} footer={cardFooter()} style={{"cursor":"pointer"}} onClick={(evt) => {handleButton(evt,"inventory")}}>
								<div style={{"minHeight":"180px","textAlign":"center","paddingTop":"10%","color":"grey"}}>
									<i className="pi pi-book mr-2" style={{"fontSize":"84px"}}></i>
								</div>
							</Card>
						</Grid>
					}
				</Grid>
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
			</BlockUI>
		);
	}else{
		return(
			<React.Fragment>
				<LinearProgress />
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
			</React.Fragment>
		);
	}
};

export default Dashboard;
