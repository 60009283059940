import React from "react";
import {useState,useEffect} from "react";
import {useLocation,useNavigate} from "react-router-dom";
import {BlockUI} from "primereact/blockui";
import {Button} from "primereact/button";
import {Box} from "@mui/system";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {InputText} from "primereact/inputtext";
import {ConfirmDialog} from "primereact/confirmdialog";
import {confirmDialog} from "primereact/confirmdialog";
import {Dialog} from "primereact/dialog";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import Message from "../Helpers/Message.js";
import {fetch_url_post} from "../../Sources/js/app.js";

let oper="";

let defaultInputValues={
	customer_id: 0,
	name: "",
	address: "",
	phone: "",
	email: "",
	trn: "",
	filter_name: "",
};

let defaultDataValues={
	clientsList: [],
};

let defaultDialogValues={
	openAddEdit: false,
	titleAddEdit: "",
};

const Clients = (props) => {

	var timerId=null;
	const [dataOk, setDataOk] = useState(false);
	const [blockScreen, setBlockScreen] = useState(false);
	const [messageText, setMessageText] = useState(null);
	const [inputValues, setInputValues] = useState(defaultInputValues);
	const [dataValues, setDataValues] = useState(defaultDataValues);
	const [clientsList, setClientsList] = useState([]);
	const [dialogValues, setDialogValues] = useState(defaultDialogValues);
    const location = useLocation();
    const navigate = useNavigate();

	const handleButton = (evt,what,elementData) => {
		if(what==="add") {
			setInputValues(defaultInputValues);
			setDialogValues({...dialogValues,openAddEdit: true,titleAddEdit: "Add new client"});
		}
		if(what==="edit") {
			setInputValues(elementData);
			setDialogValues({...dialogValues,openAddEdit: true,titleAddEdit: "Edit client"});
		}
		if(what==="delete") {
			let url="deleteClient";
			url+="?session_id="+props.session.sessId;
			oper="delete";
			let data={
				id: elementData.id,
			};
			timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
		}
		if(what==="save") {
			document.querySelectorAll("input").forEach((element)=>element.classList.remove("required_input_error"));
			if(inputValues.name==="") {
				setMessageText("Error: Please fill client name!");				
				document.getElementById("name").classList.add("required_input_error");
				return false;
			}
			if(inputValues.address==="") {
				setMessageText("Error: Please fill client address!");				
				document.getElementById("address").classList.add("required_input_error");
				return false;
			}
			let url="saveClient";
			url+="?session_id="+props.session.sessId;
			oper="save";
			let data={...inputValues};
			timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
		}
	}

	const handleChange = evt => {
		const {name, value} = evt.target;
		if(name==="filter_name") {
			setClientsList(dataValues.clientsList.filter((item)=>((item.name.toLowerCase().indexOf(value.toLowerCase())>-1))||(value==="")));
		}
		setInputValues({...inputValues,[name]:value});
	}

	const success_handler = (result) => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
			setMessageText("");
		}
		if(result.ok===0) {
			setMessageText("Error: "+result.error);
			return false;
		}
		if(result.is_auth===0) {
			navigate("/login");			
			return false;
		}
		if(result.message) {
			setMessageText(result.message);			
		}
		if((oper==="get")||(oper==="save")||(oper==="delete")) {
			setDataValues({...dataValues,clientsList:(result.clientsList?result.clientsList:[])});
			setClientsList(result.clientsList?result.clientsList:[]);
			if(oper==="save") {
				setDialogValues(defaultDialogValues);
			}
            if(!dataOk) {
                setDataOk(true);
            }
			oper="";
		}
	}

	const fail_handler = () => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
		}
		setMessageText("Connection error please try again later!");
	}

	const wait_handler = () => {
		setBlockScreen(true);
		setMessageText("Operation is in progress. Please wait...");
	}

	useEffect(() => {
		if(props.session.sessId) {
			oper="get";
			let url="getClients";
			url+="?session_id="+props.session.sessId;
			let data={};
			fetch_url_post(url,data,success_handler,fail_handler);
		}else{
			navigate("/login");
		}
	},[]);

	const tableColTemplate = (row_data,column) => {
		if(column.field!=="buttons") {
			return(
				<span style={{"paddingLeft":"1px"}}>{row_data[column.field]}</span>
			)
		}
		if(column.field==="buttons") {
			return(
				<span>
					<Button label="Edit" icon="pi pi-pencil" tooltip="Edit client" className="p-button-sm p-button-raised" style={{"marginRight":"3px"}} onClick={(evt) => handleButton(evt,"edit",row_data)}/>
					<Button label="Delete" icon="pi pi-trash" tooltip="Delete client" className="p-button-sm p-button-raised p-button-danger" onClick={(evt) => confirm(evt,"delete",row_data)}/>
				</span>
			);
		}
	}

	const tableColTemplateSmall = (row_data,column) => {
		if(column.field!=="buttons") {
			return(
				<span style={{"paddingLeft":"1px"}}>{row_data[column.field]}</span>
			);
		}
		if(column.field==="buttons") {
			return(
				<span>
					<Button icon="pi pi-pencil" tooltip="Edit client" className="p-button-sm p-button-raised" style={{"marginRight":"3px"}} onClick={(evt) => handleButton(evt,"edit",row_data)}/>
					<Button icon="pi pi-trash" tooltip="Delete client" className="p-button-sm p-button-raised p-button-danger" onClick={(evt) => confirm(evt,"delete",row_data)}/>
				</span>
			);
		}
	}

	const addEditDialog = () => {
		return(
			<Dialog header={dialogValues.titleAddEdit} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openAddEdit:false})} className="responsive-dialog-small-med">
				<Grid container spacing={1} style={{"paddingTop":"12px"}}>
					<Grid item xs={12} sm={12} md={12} lg={8} className="field padding-med">
						<label htmlFor="name">Name*</label>
						<InputText name="name" id="name" style={{"width":"100%"}} value={inputValues.name} onChange={(evt) => handleChange(evt)}/>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={4} className="field padding-med">
						<label htmlFor="trn">TRN</label>
						<InputText name="trn" id="trn" style={{"width":"100%"}} value={inputValues.trn} onChange={(evt) => handleChange(evt)}/>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} className="field padding-med">
						<label htmlFor="address">Address*</label>
						<InputText name="address" id="address" style={{"width":"100%"}} value={inputValues.address} onChange={(evt) => handleChange(evt)}/>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={5} className="field padding-med">
						<label htmlFor="phone">Phone</label>
						<InputText name="phone" id="phone" style={{"width":"100%"}} value={inputValues.phone} onChange={(evt) => handleChange(evt)}/>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={7} className="field padding-med">
						<label htmlFor="email">Email address</label>
						<InputText name="email" id="email" style={{"width":"100%"}} value={inputValues.email} onChange={(evt) => handleChange(evt)}/>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} style={{"textAlign":"right","marginTop":"12px"}}>
						<Button label="Save" icon="pi pi-check-circle" className="p-button-sm p-button-success p-button-raised" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"save")}/>
						<Button label="Close" icon="pi pi-times" className="p-button-sm p-button-danger p-button-raised" onClick={(evt)=>setDialogValues({...dialogValues,openAddEdit:false})}/>
					</Grid>
				</Grid>
			</Dialog>
		);
	}

	const confirm = (evt,what,elementData) => {
		if(what==="delete") {
				confirmDialog({
						message: "Are you sure you want to request deletion?",
						header: "Confirm delete request",
						icon: "pi pi-exclamation-triangle",
						accept: () => handleButton(evt,what,elementData),
						reject: () => null,
				});
		}
	}

	if(dataOk) {
		return(
			<BlockUI blocked={blockScreen} fullScreen>
				<Grid container spacing={1} style={{"marginLeft":"0px","width":"99.99%"}}>
					<Grid item xs={8} sm={8} md={4} lg={4} xl={4} className="field padding-min">
						<InputText name="filter_name" id="filter_name" style={{"width":"100%","marginBottom":"12px"}} placeholder="Filter by client..." value={inputValues.filter_name} onChange={(evt) => handleChange(evt)} />
					</Grid>
					<Grid item xs={4} sm={4} md={8} lg={8} xl={8} className="field padding-med text-right">
						<Button label="Add client" icon="pi pi-plus-circle" className="p-button-sm p-button-primary" tooltip="Add new client" style={{}} onClick={(evt) => {handleButton(evt,"add")}}/>
					</Grid>						
				</Grid>
				<React.Fragment>
					<Box sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block", xl: "block" } }}>
						<div className="card" style={{"marginTop":"3px"}}>
							<DataTable
								value={clientsList}
								scrollable
								scrollHeight="60vh"
								style={{"minHeight":"63vh"}}
								scrollDirection="both"
								stripedRows
								resizableColumns
								columnResizeMode="fit"
								emptyMessage={<p style={{"textAlign":"center"}}>No entries found...</p>}
							>
								<Column field="name" header="Client name" style={{"flexGrow":"1","flexBasis":"180px"}} body={tableColTemplate} sortable></Column>
								<Column field="trn" header="TRN" style={{"flexGrow":"0","flexBasis":"160px"}} body={tableColTemplate} sortable></Column>
								<Column field="address" header="Client address" style={{"flexGrow":"1","flexBasis":"300px"}} body={tableColTemplate} sortable></Column>
								<Column field="phone" header="Phone" style={{"flexGrow":"1","flexBasis":"110px"}} body={tableColTemplate} sortable></Column>
								<Column field="reg_date_parsed" header="Created at" style={{"flexGrow":"0","flexBasis":"130px"}} body={tableColTemplate} sortable></Column>
								<Column field="buttons" header="" style={{"flexGrow":"0","flexBasis":"153px"}} body={tableColTemplate}></Column>
							</DataTable>
						</div>
					</Box>
					<Box sx={{ display: { xs: "block", sm: "block", md: "block", lg: "none", xl: "none" } }}>
						<div style={{"marginTop":"3px"}}>
							<DataTable
								value={clientsList}
								responsiveLayout="stack"
								stripedRows
								emptyMessage={<p style={{"textAlign":"center"}}>No entries found...</p>}
							>
								<Column field="name" header="Client name" style={{}} sortable></Column>
								<Column field="trn" header="TRN" style={{}} sortable></Column>
								<Column field="address" header="Address" style={{}} sortable></Column>
								<Column field="phone" header="Phone" style={{}}></Column>
								<Column field="reg_date_parsed" header="Created at" style={{}}></Column>
								<Column field="buttons" header="" style={{}} body={tableColTemplateSmall}></Column>
							</DataTable>
						</div>
					</Box>
				</React.Fragment>
				<ConfirmDialog />
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
				{dialogValues.openAddEdit && addEditDialog()}
			</BlockUI>
		);
	}else{
		return(
			<React.Fragment>
				<LinearProgress />
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
			</React.Fragment>
		);
	}
};

export default Clients;
